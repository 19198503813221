import React, { useState, useMemo } from 'react'
import { get } from 'lodash'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import Filter from './ShowContractsFilter'
import Table from './ShowContractsTable'
import { directTableContainerStyles } from '../shared/elements'
import * as paths from '../../utils/paths'
import { formatStatObject, formatStatSumObject } from '../../utils/helpers'

const Container = styled.div`
  ${directTableContainerStyles}
`

export default function Contracts(props) {
  const { publisher, contracts, offerStats } = props

  const [filterQuery, setFilterQuery] = useState('')
  const [filterStatus, setFilterStatus] = useState('')
  const [order, setOrder] = useState('code')
  const [sort, setSort] = useState('asc')

  const shownContracts = useMemo(
    () =>
      contracts
        .map((contract) => {
          const stat = offerStats?.find(
            (stats) => stats.offer.id === contract.tuneOfferId
          )

          return {
            ...contract,
            stats: formatStatObject(stat),
          }
        })
        .filter((contract) => {
          const queryMatch =
            !filterQuery ||
            contract.code.toLowerCase().indexOf(filterQuery.toLowerCase()) !==
              -1
          const statusMatch = !filterStatus || contract.status === filterStatus

          return queryMatch && statusMatch
        })
        .sort((contractA, contractB) => {
          const a = get(contractA, order)
          const b = get(contractB, order)
          const asc = sort === 'asc'

          if (a < b) {
            return asc ? -1 : 1
          } else if (a > b) {
            return asc ? 1 : -1
          } else {
            return 0
          }
        }),
    [contracts, offerStats, filterQuery, filterStatus, order, sort]
  )

  const totalStats = useMemo(() => formatStatSumObject(offerStats), [
    offerStats,
  ])

  return (
    <Container>
      <div className="panel">
        <div className="header">
          <a
            href={paths.newPublisherContractPath(publisher.uuid)}
            className="add inline-flex-center"
          >
            Add campaign <SVG src="/images/icons/plus.svg" />
          </a>
          <Filter
            {...{
              filterQuery,
              setFilterQuery,
              filterStatus,
              setFilterStatus,
            }}
          />
        </div>
        <Table
          {...{
            publisher,
            contracts: shownContracts,
            totalStats,
            order,
            setOrder,
            sort,
            setSort,
          }}
        />
      </div>
    </Container>
  )
}
