import React from 'react'
import SVG from 'react-inlinesvg'
import { TextField, BlockRadio } from '../shared/fields'
import { PublisherSelect, ConversionRule } from '../shared/elements'
import { validates } from '../../utils/constants'
import { getValue } from '../../utils/helpers'

export default function ContractFormNewContract(props) {
  const { form, contract, publishers, conversionRuleSets } = props
  const { getValues } = form

  return (
    <>
      <div className="panel px-35 mb-4">
        <h5 className="m-0">Campaign name</h5>

        <div>
          <p className="mt-3">Edit the name of the campaign if needed.</p>
          <TextField
            form={form}
            validation={validates.required}
            name="code"
            placeholder="Campaign name"
            wrapperClassName="mb-3"
          />

          <p className="mt-4">Edit default campaign landing page.</p>
          <TextField
            form={form}
            validation={validates.required}
            name="url"
            placeholder="Campaign URL"
            wrapperClassName="mb-1"
          />
        </div>
      </div>

      <div className="panel px-35 mb-4">
        <h5 className="m-0">Partner access</h5>

        <div>
          <p className="mt-3">
            Add partners that are allowed to send traffic to this campaign.
          </p>
          <PublisherSelect
            form={form}
            publishers={publishers}
            exclude={[contract?.publisher?.uuid]}
            wrapperClassName="mt-35 mb-1"
          />
        </div>
      </div>

      <div className="panel px-35 mb-4">
        <h5 className="m-0">Attribution model</h5>

        <div>
          <p className="mt-3">Choose the attribution model of the campaign.</p>
        </div>

        <div className="attribution-types">
          {conversionRuleSets?.map((conversionRuleSet) => (
            <BlockRadio
              key={conversionRuleSet.uuid}
              form={form}
              name="data.conversionRuleSet"
              value={conversionRuleSet.uuid}
              Label={(props) => (
                <>
                  <h6>{conversionRuleSet.name}</h6>
                  <div className="icon">{conversionRuleSet.abbr}</div>
                  <div className="hint">{conversionRuleSet.description}</div>
                </>
              )}
            />
          ))}

          <BlockRadio
            form={form}
            name="data.conversionRuleSet"
            value="custom"
            Label={(props) => (
              <>
                <h6>Custom model</h6>
                <SVG
                  src="/images/icons/attribution-model-custom.svg"
                  width={54}
                  height={54}
                />
                <div className="hint">
                  Define rules of a custom attribution model.
                </div>
              </>
            )}
          />
        </div>

        <div
          className={`row mt-4 ${
            getValue(getValues, 'data.conversionRuleSet') === 'custom'
              ? ''
              : 'd-none'
          }`}
        >
          <h5 className="mb-1">Create a custom attribution model</h5>

          <ConversionRule form={form} />
        </div>
      </div>
    </>
  )
}
