import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useForm } from 'react-hook-form'
import { appContext } from '../shared/contexts'
import { Dialog, ShortlinkCode } from '../shared/elements'
import { TextField } from '../shared/fields'
import { postRequest, putRequest, deleteRequest } from '../../services/api'
import { getCurrentWebsite } from '../../services/auth'

const Form = styled.form`
  .new-form-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .edit-form-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-link {
      margin-left: -12px;
      font-size: 14px;
      font-weight: normal;
      text-decoration: none;
    }

    svg {
      width: 12px;
      height: 12px;
      margin-top: -2px;
      margin-right: 8px;
    }
  }
`

export default function FormDialog(props) {
  const { show, toggle, contract, shortlink, onShare, onDone } = props

  const { showMessage } = useContext(appContext)

  const [newCode, setNewCode] = useState('')

  const isNew = !shortlink
  const currentWebsite = getCurrentWebsite()

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      name: ' ',
      targetUrl: ' ',
      publisher: contract.publisher.name,
      contract: contract.code,
      source: currentWebsite,
      ...shortlink,
    },
  })

  const { reset } = form
  useEffect(() => {
    const website = getCurrentWebsite()
    reset({
      name: ' ',
      targetUrl: ' ',
      publisher: contract.publisher.name,
      contract: contract.code,
      source: website,
      ...shortlink,
    })
    if (!show) {
      setNewCode('')
    }
  }, [reset, show, contract, shortlink])

  const remove = async () => {
    if (window.confirm('Are you sure you want to delete this smart link?')) {
      await deleteRequest(`/advertiser/shortlinks/${shortlink.uuid}`)

      toggle(false)
      if (onDone) {
        onDone()
      }
    }
  }

  const onSubmit = async (values) => {
    let response
    if (isNew) {
      const shortlink = values
      shortlink.contractUuid = contract.uuid
      shortlink.publisherUuid = contract.publisher.uuid
      response = await postRequest(`/advertiser/shortlinks/`, { shortlink })
    } else {
      response = await putRequest(`/advertiser/shortlinks/${shortlink.uuid}`, {
        shortlink: values,
      })
    }

    if (response?.data?.success) {
      if (response?.data?.shortlink) {
        setNewCode(response.data.shortlink.code)
      } else {
        onClose()
      }
    } else {
      showMessage(response?.message || 'Something went wrong')
    }
  }

  const onClose = () => {
    toggle(false)
    setNewCode('')
    if (onDone) {
      onDone()
    }
  }

  return (
    <Dialog
      show={show}
      toggle={toggle}
      dialogClassName={newCode ? 'bg-gray' : ''}
    >
      {!newCode ? (
        <>
          <h4 className="mt--25 mb-30">{isNew ? 'New' : 'Edit'} smart link</h4>
          <Form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  form={form}
                  validation={{ required: true }}
                  name="name"
                  label="Smart link name"
                />
              </div>

              <div className="col-md-6">
                <TextField
                  form={form}
                  name="publisher"
                  label="Partner"
                  disabled={true}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <TextField
                  form={form}
                  name="contract"
                  label="Campaign"
                  disabled={true}
                />
              </div>

              <div className="col-md-6">
                <TextField
                  form={form}
                  name="source"
                  label="Source"
                  disabled={true}
                />
              </div>
            </div>

            <h5 className="mb-4">Options</h5>
            <TextField
              form={form}
              name="targetUrl"
              label="Destination URL"
              hint="Use this if you want visitors to land on another page than your homepage."
            />

            {isNew ? (
              <div className="new-form-actions">
                <button className="btn btn-primary px-5">Done</button>
              </div>
            ) : (
              <div className="edit-form-actions">
                <button
                  type="button"
                  onClick={remove}
                  className="btn btn-link text-danger"
                >
                  <SVG src="/images/icons/trash.svg" />
                  Delete this smart link
                </button>
                <button className="btn btn-primary px-5">Save</button>
              </div>
            )}
          </Form>
        </>
      ) : (
        <>
          <h2 className="text-center">Good job!</h2>
          <h5 className="text-center">Here is your smart link</h5>
          <div className="panel mt-4 mb-5">
            <ShortlinkCode
              contract={contract}
              code={newCode}
              share={() => {
                onShare(newCode)()
                toggle(false)
              }}
            />
            <div className="text-hint">
              Copy or send this link to the Partner that will be sending traffic
              to you
            </div>
          </div>
          <div className="text-right">
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={onClose}
            >
              Done
            </button>
          </div>
        </>
      )}
    </Dialog>
  )
}
