import React, { useState } from 'react'
import styled from 'styled-components'
import Table from './ListTable'
import Pagination from './ListPagination'
import SearchQuery from './ListSearch'
import {
  PageTitle,
  PageContent,
  SpinnerRect,
  SourcePicker,
  useSourcePickerState,
  DateRangePicker,
  useDateRangePickerState,
  directTableContainerStyles,
} from '../shared/elements'
import { useApiLoad } from '../shared/hooks'
import { putRequest } from '../../services/api'
import { toQuery } from '../../utils/helpers'

const Container = styled.div`
  ${directTableContainerStyles}

  .overview-filters {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;

    > * {
      margin-right: 16px;
    }
  }

  .conversion-actions {
    min-height: 32px;

    button {
      position: relative;
      top: -4px;
      height: 32px;
      padding: 0 16px;
      margin-right: 8px;
      border: none;
      border-radius: 2px;
      color: var(--text-primary);
      background: var(--text-muted);
      font-family: inherit;
      font-weight: 500;
      cursor: pointer;
      transition: background 0.3s ease-in-out;

      &:hover {
        background: var(--input-background);
      }
    }
  }
`

export default function ConversionsList(props) {
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [order, setOrder] = useState('datetime')
  const [sort, setSort] = useState('desc')

  const [searchQuery, setSearchQuery] = useState('')
  const dateRangePickerState = useDateRangePickerState()
  const { startDate, defaultStartDate, endDate, defaultEndDate } =
    dateRangePickerState

  const sourcePickerState = useSourcePickerState()
  const {
    sourceUuid,
    setSourceUuid,
    sourceType,
    setSourceType,
    setSourceName,
    setSourceContracts,
    setSourcePublishers,
  } = sourcePickerState

  const [conversions, setConversions] = useState(null)

  const query = toQuery({
    startDate: (startDate || defaultStartDate).toISOString(),
    endDate: (endDate || defaultEndDate).toISOString(),
    filterUuid: sourceUuid,
    filterType: sourceType,
    filterQuery: searchQuery,
    page,
    order,
    sort,
  })
  useApiLoad(
    `/advertiser/conversions?${query}`,
    conversions === null,
    (response) => {
      setConversions(response?.data?.conversions)
      setSourceContracts(response?.data?.searchContracts)
      setSourcePublishers(response?.data?.searchPublishers)
      setPages(response?.data?.pageCount)
    }
  )

  const [selectedConversions, setSelectedConversions] = useState([])

  const toggleConversionSelect = (tuneEventId) => {
    const newSelectedConversions = [...selectedConversions]
    if (newSelectedConversions.includes(tuneEventId)) {
      newSelectedConversions.splice(selectedConversions.indexOf(tuneEventId), 1)
    } else {
      newSelectedConversions.push(tuneEventId)
    }
    setSelectedConversions(newSelectedConversions)
  }

  const toggleAllConversionSelect = () => {
    if (selectedConversions.length === conversions.length) {
      setSelectedConversions([])
    } else {
      setSelectedConversions(
        conversions.map((conversion) => conversion.tune_event_id)
      )
    }
  }

  const setPublisherSource = (publisher) => {
    setSourceType('publisher')
    setSourceUuid(publisher.uuid)
    setSourceName(publisher.name)
    reloadData()
  }

  const setSearch = (query) => {
    if (query !== searchQuery) {
      setSearchQuery(query)
      reloadData()
    }
  }

  const updateConversionStatus = (tuneEventIds, status) => {
    if (!Array.isArray(tuneEventIds)) {
      tuneEventIds = [tuneEventIds]
    }

    const newConversions = [...conversions]
    const newSelectedConversions = [...selectedConversions]
    for (const tuneEventId of tuneEventIds) {
      const newConversion =
        newConversions.find(
          (conversion) => conversion.tune_event_id === tuneEventId
        ) || {}
      newConversion.status = status

      const selectedIndex = newSelectedConversions.indexOf(tuneEventId)
      if (selectedIndex !== -1) {
        newSelectedConversions.splice(selectedIndex, 1)
      }
    }
    setConversions(newConversions)
    setSelectedConversions(newSelectedConversions)

    return putRequest('/advertiser/conversions', {
      tuneEventIds,
      status,
    })
  }

  const reloadData = () => {
    setSelectedConversions([])
    setConversions(null)
    setPage(1)
    setPages(1)
  }

  const setPageAndReload = (newPage) => {
    if (newPage !== page) {
      setPage(newPage)
      setSelectedConversions([])
      setConversions(null)
    }
  }

  const setOrderAndReload = (newOrder) => {
    if (newOrder !== order) {
      setOrder(newOrder)
      reloadData()
    }
  }

  const setSortAndReload = (newSort) => {
    if (newSort !== sort) {
      setSort(newSort)
      reloadData()
    }
  }

  return (
    <Container>
      <PageTitle title="Conversions" />

      <PageContent>
        <div className="overview-filters">
          <SearchQuery initialValue={searchQuery} updateValue={setSearch} />
          <SourcePicker state={sourcePickerState} onChange={reloadData} />
          <DateRangePicker state={dateRangePickerState} onChange={reloadData} />
        </div>

        <div className="panel">
          {conversions === null ? (
            <div className="text-center">
              <SpinnerRect />
            </div>
          ) : (
            <>
              <Table
                {...{
                  conversions,
                  selectedConversions,
                  toggleConversionSelect,
                  toggleAllConversionSelect,
                  updateConversionStatus,
                  setPublisherSource,
                  order,
                  setOrder: setOrderAndReload,
                  sort,
                  setSort: setSortAndReload,
                }}
              />
              <div className="footer">
                <div className="conversion-actions">
                  {selectedConversions.length > 0 && (
                    <>
                      <button
                        onClick={() => {
                          updateConversionStatus(
                            selectedConversions,
                            'approved'
                          )
                        }}
                      >
                        Approve selected
                      </button>
                      <button
                        onClick={() => {
                          updateConversionStatus(
                            selectedConversions,
                            'rejected'
                          )
                        }}
                      >
                        Reject selected
                      </button>
                    </>
                  )}
                </div>

                <Pagination
                  page={page}
                  pages={pages}
                  setPage={setPageAndReload}
                />
              </div>
            </>
          )}
        </div>
      </PageContent>
    </Container>
  )
}
