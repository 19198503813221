import React, { useContext } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { appContext } from '../shared/contexts'
import { SortColumn, directTableStyles } from '../shared/elements'
import { copyToClipboard } from '../../utils/helpers'
import {
  formatStatValue,
  formatStatPercentage,
  formatStatMoney,
} from '../../utils/helpers/stats'
import { shortlinkHost } from '../../utils/constants'

const Container = styled.div`
  ${directTableStyles}

  tbody {
    .shortlink-name {
      button {
        font-family: inherit;
        font-weight: bold;
        color: inherit;

        &:hover {
          color: var(--text-primary-hover);
        }
      }

      svg {
        width: 12px;
        height: 12px;
        margin-left: 8px;
        color: var(--text-light);
        opacity: 0;
        transition: opacity 0.3s;
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }
    }

    .shortlink-url {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      a {
        font-family: inherit;
        color: inherit;

        &:hover {
          color: var(--text-primary-hover);
        }
      }

      button {
        padding: 0 8px;
        margin-left: 8px;
        border: none;
        color: var(--text-primary);
        background: transparent;
        outline: none;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: var(--text-primary-hover);
        }

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
`

export default function Table(props) {
  const {
    contract,
    shortlinks,
    editShortlink,
    totalStats,
    order,
    setOrder,
    sort,
    setSort,
  } = props

  const { showMessage } = useContext(appContext)

  if (shortlinks === null) {
    return (
      <Container>
        <table className="table table-borderless">
          <thead>
            <tr>
              <td>Loading...</td>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td className="text-center text-primary py-5">
                <div className="spinner-border" role="status" />
              </td>
            </tr>
          </tfoot>
        </table>
      </Container>
    )
  }

  const calculateDefaultValue = (field) => {
    const shortlinksSum = shortlinks.reduce(
      (sum, shortlink) => sum + shortlink.stats[field] || 0,
      0
    )
    return totalStats[field] - shortlinksSum
  }

  const sortProps = {
    order,
    setOrder,
    sort,
    setSort,
  }

  return (
    <Container>
      <table className="table table-borderless">
        <thead>
          <tr>
            <td className="spacer" />
            <SortColumn
              className="text-left"
              field="name"
              label="Name"
              defaultSort="asc"
              {...sortProps}
            />
            <SortColumn
              className="text-left"
              field="code"
              label="Smart link"
              defaultSort="asc"
              {...sortProps}
            />
            <SortColumn
              className="text-left"
              field="targetUrl"
              label="Destination"
              defaultSort="asc"
              {...sortProps}
            />
            {/*
            <SortColumn
              field="endsAt"
              label="Expires at"
              defaultSort="asc"
              {...sortProps}
            />
            */}
            <SortColumn
              className="text-right"
              field="stats.saleAmount"
              label="Total sales"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.conversionCount"
              label="Conversions"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.conversionRate"
              label="CR"
              title="Conversion rate"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              className="text-right"
              field="stats.avgSaleAmount"
              label="AOV"
              title="Average order value"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.grossClicks"
              label="Clicks"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.uniqueClicks"
              label="Unique clicks"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.nonUniqueClicks"
              label="Non-unique clicks"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.rejectedClicks"
              label="R. clicks"
              title="Rejected clicks"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.suspiciousClicks"
              label="S. clicks"
              title="Suspicious clicks"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              className="text-right"
              field="stats.payout"
              label="Traffic cost"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              className="text-right"
              field="stats.acquisitionCost"
              label="Customer acquisition cost"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              className="text-right"
              field="stats.profit"
              label="Profit"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.newCustomerRate"
              label="New customer rate"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.itemsPerOrder"
              label="Items per order"
              defaultSort="desc"
              {...sortProps}
            />
            <td className="spacer" />
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="spacer" />
            <th className="text-left">
              <div className="shortlink-name">Default smart link</div>
            </th>
            <td className="text-left">
              <div
                className={`shortlink-url shortlink-url-${contract.shortlinkCode}`}
              >
                <a
                  href={`${shortlinkHost}/${contract.shortlinkCode}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {shortlinkHost}/{contract.shortlinkCode}
                </a>
                <button
                  onClick={copyToClipboard(
                    `.shortlink-url-${contract.shortlinkCode} a`,
                    () => showMessage('Link copied to clipboard')
                  )}
                >
                  <SVG src="/images/icons/clipboard.svg" />
                </button>
              </div>
            </td>
            <td className="text-left">
              <div className="shortlink-url">
                <a href={contract.url} target="_blank" rel="noreferrer">
                  {contract.url}
                </a>
              </div>
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(
                calculateDefaultValue('saleAmount'),
                contract.data.currency
              )}
            </td>
            <td className="with-separator">
              {formatStatValue(calculateDefaultValue('conversionCount'))}
            </td>
            <td className="with-separator">
              {formatStatPercentage(calculateDefaultValue('conversionRate'))}
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(
                calculateDefaultValue('avgSaleAmount'),
                contract.data.currency
              )}
            </td>
            <td className="with-separator">
              {formatStatValue(calculateDefaultValue('grossClicks'))}
            </td>
            <td className="with-separator">
              {formatStatValue(calculateDefaultValue('uniqueClicks'))}
            </td>
            <td className="with-separator">
              {formatStatValue(calculateDefaultValue('nonUniqueClicks'))}
            </td>
            <td className="with-separator">
              {formatStatValue(calculateDefaultValue('rejectedClicks'))}
            </td>
            <td className="with-separator">
              {formatStatValue(calculateDefaultValue('suspiciousClicks'))}
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(
                calculateDefaultValue('payout'),
                contract.data.currency
              )}
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(
                calculateDefaultValue('acquisitionCost'),
                contract.data.currency
              )}
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(
                calculateDefaultValue('profit'),
                contract.data.currency
              )}
            </td>
            <td className="with-separator">
              {formatStatPercentage(calculateDefaultValue('newCustomerRate'))}
            </td>
            <td className="without-separator">
              {formatStatValue(calculateDefaultValue('itemsPerOrder'), true)}
            </td>
            <td className="spacer" />
          </tr>

          {shortlinks.map((record) => (
            <tr key={record.uuid}>
              <td className="spacer" />
              <th className="text-left">
                <div className="shortlink-name">
                  <button className="a" onClick={editShortlink(record)}>
                    {record.name}
                    <SVG src="/images/icons/pencil-filled.svg" />
                  </button>
                </div>
              </th>
              <td className="text-left">
                <div className={`shortlink-url shortlink-url-${record.code}`}>
                  <a
                    href={`${shortlinkHost}/${record.code}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {shortlinkHost}/{record.code}
                  </a>
                  <button
                    onClick={copyToClipboard(
                      `.shortlink-url-${record.code} a`,
                      () => showMessage('Link copied to clipboard')
                    )}
                  >
                    <SVG src="/images/icons/clipboard.svg" />
                  </button>
                </div>
              </td>
              <td className="text-left">
                <div className="shortlink-url">
                  <a href={record.targetUrl} target="_blank" rel="noreferrer">
                    {record.targetUrl}
                  </a>
                </div>
              </td>
              <td className="with-separator text-right">
                {formatStatMoney(
                  record.stats?.saleAmount,
                  record.stats?.currency
                )}
              </td>
              <td className="with-separator">
                {formatStatValue(record.stats?.conversionCount)}
              </td>
              <td className="with-separator">
                {formatStatPercentage(record.stats?.conversionRate)}
              </td>
              <td className="with-separator text-right">
                {formatStatMoney(
                  record.stats?.avgSaleAmount,
                  record.stats?.currency
                )}
              </td>
              <td className="with-separator">
                {formatStatValue(record.stats?.grossClicks)}
              </td>
              <td className="with-separator">
                {formatStatValue(record.stats?.uniqueClicks)}
              </td>
              <td className="with-separator">
                {formatStatValue(record.stats?.nonUniqueClicks)}
              </td>
              <td className="with-separator">
                {formatStatValue(record.stats?.rejectedClicks)}
              </td>
              <td className="with-separator">
                {formatStatValue(record.stats?.suspiciousClicks)}
              </td>
              <td className="with-separator text-right">
                {formatStatMoney(record.stats?.payout, record.stats?.currency)}
              </td>
              <td className="with-separator text-right">
                {formatStatMoney(
                  record.stats?.acquisitionCost,
                  record.stats?.currency
                )}
              </td>
              <td className="with-separator text-right">
                {formatStatMoney(record.stats?.profit, record.stats?.currency)}
              </td>
              <td className="with-separator">
                {formatStatPercentage(record.stats?.newCustomerRate)}
              </td>
              <td className="without-separator">
                {formatStatValue(record.stats?.itemsPerOrder, true)}
              </td>
              <td className="spacer" />
            </tr>
          ))}

          <tr className="total">
            <td className="spacer" />
            <th className="text-left">
              <span>Total</span>
            </th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <td className="with-separator text-right">
              {formatStatMoney(totalStats?.saleAmount, totalStats?.currency)}
            </td>
            <td className="with-separator">
              {formatStatValue(totalStats?.conversionCount)}
            </td>
            <td className="with-separator">
              {formatStatPercentage(totalStats?.conversionRate)}
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(totalStats?.avgSaleAmount, totalStats?.currency)}
            </td>
            <td className="with-separator">
              {formatStatValue(totalStats?.grossClicks)}
            </td>
            <td className="with-separator">
              {formatStatValue(totalStats?.uniqueClicks)}
            </td>
            <td className="with-separator">
              {formatStatValue(totalStats?.nonUniqueClicks)}
            </td>
            <td className="with-separator">
              {formatStatValue(totalStats?.rejectedClicks)}
            </td>
            <td className="with-separator">
              {formatStatValue(totalStats?.suspiciousClicks)}
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(totalStats?.payout, totalStats?.currency)}
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(
                totalStats?.acquisitionCost,
                totalStats?.currency
              )}
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(totalStats?.profit, totalStats?.currency)}
            </td>
            <td className="with-separator">
              {formatStatPercentage(totalStats?.newCustomerRate)}
            </td>
            <td className="without-separator">
              {formatStatValue(totalStats?.itemsPerOrder, true)}
            </td>
            <td className="spacer" />
          </tr>
        </tbody>
      </table>
    </Container>
  )
}
