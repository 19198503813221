import React, { useState, createContext, useContext } from 'react'
import { useForm } from 'react-hook-form'
import Code from './FormCode'
import Publisher from './FormPublisher'
import Terms from './FormTerms'
import Billing from './FormBilling'
import Shortlinks from './FormShortlinks'
import ShortlinkFormDialog from './ShortlinksFormDialog'
import ShortlinkShareDialog from './ShortlinksShareDialog'
import { validate } from './FormValidation'
import { appContext } from '../shared/contexts'
import { defaultContractDataValues } from '../../utils/constants'

export const formContext = createContext({})
const FormContextProvider = formContext.Provider

export default function ContractNewForm(props) {
  const {
    contract,
    isNewContract,
    isNewPublisher,
    paymentMethods,
    showAddPaymentMethod,
    setShowAddPaymentMethod,
    currentPage,
    setCurrentPage,
  } = props

  const { showMessage } = useContext(appContext)

  const [submitting, setSubmitting] = useState(false)

  const [showShortlinkShare, setShowShortlinkShare] = useState(false)
  const [shareCode, setShareCode] = useState('')

  const [showShortlinkForm, setShowShortlinkForm] = useState(false)
  const [formShortlink, setFormShortlink] = useState(null)
  const [formShortlinkIndex, setFormShortlinkIndex] = useState(null)

  const [shortlinks, setShortlinks] = useState([])
  const [shortlinkShares, setShortlinkShares] = useState([])

  const defaultBillingMethod =
    (paymentMethods && paymentMethods[0]?.id) || 'invoice'
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      code: '',
      ...contract,
      data: {
        ...defaultContractDataValues,
        billingMethod: defaultBillingMethod,
        ...contract?.data,
        ...contract?.pendingData,
      },
      publisher: {
        name: '',
      },
    },
  })

  const minPage = isNewPublisher ? 0 : 1
  const maxPage = isNewContract ? 3 : 2
  const gotoPage = (nextPage) => {
    if (nextPage >= minPage && nextPage <= maxPage) {
      setCurrentPage(nextPage)
    }
  }
  const goBack = () => {
    gotoPage(currentPage - 1)
  }
  const goForward = async () => {
    const validationResult = await validate(form, currentPage)
    if (validationResult === true) {
      gotoPage(currentPage + 1)
    } else {
      showMessage(validationResult)
    }
  }

  const submit = () => {
    setSubmitting(true)
    const callback = (values) =>
      props.onSubmit({ ...values, shortlinks, shortlinkShares })
    form
      .handleSubmit(callback)()
      .then(() => {
        setSubmitting(false)
      })
      .catch((error) => {
        showMessage(`Something went wrong: ${error}`)
        setSubmitting(false)
      })
  }

  const fieldsetClassName = (index) => (currentPage === index ? '' : 'd-none')

  return (
    <FormContextProvider
      value={{
        setShowShortlinkShare,
        setShareCode,
        setShowShortlinkForm,
        setFormShortlink,
        setFormShortlinkIndex,
      }}
    >
      <div className="panel px-4 py-5 mt-20">
        <form>
          <div className="mb-5">
            <Code form={form} />
          </div>

          {isNewPublisher && (
            <fieldset className={fieldsetClassName(0)}>
              <Publisher {...{ form }} />
            </fieldset>
          )}
          <fieldset className={fieldsetClassName(1)}>
            <Terms {...{ form }} />
          </fieldset>
          <fieldset className={fieldsetClassName(2)}>
            <Billing
              {...{
                form,
                paymentMethods,
                showAddPaymentMethod,
                setShowAddPaymentMethod,
              }}
            />
          </fieldset>
          <fieldset className={fieldsetClassName(3)}>
            <Shortlinks {...{ form, contract, shortlinks }} />
          </fieldset>
        </form>
      </div>
      <div className="form-actions spread mt-3">
        <button
          type="button"
          className="btn btn-secondary px-5"
          disabled={submitting || currentPage <= minPage}
          onClick={goBack}
        >
          Back
        </button>

        <button
          type="button"
          className="btn btn-primary px-5"
          disabled={submitting}
          hidden={currentPage >= maxPage}
          onClick={goForward}
        >
          Continue
        </button>

        <button
          type="button"
          className="btn btn-primary px-5"
          disabled={submitting}
          hidden={currentPage < maxPage}
          onClick={submit}
        >
          {isNewContract ? 'Done' : 'Save'}
        </button>
      </div>

      <ShortlinkFormDialog
        show={showShortlinkForm}
        toggle={setShowShortlinkForm}
        form={form}
        contract={contract}
        shortlink={formShortlink}
        shortlinkIndex={formShortlinkIndex}
        onShare={(code) => {
          setShareCode(code)
          setShowShortlinkShare(true)
        }}
        addShortlink={(values) => {
          const newShortlinks = [...shortlinks, values]
          setShortlinks(newShortlinks)
        }}
        updateShortlink={(index, values) => {
          const newShortlinks = [...shortlinks]
          newShortlinks[index] = { ...newShortlinks[index], ...values }
          setShortlinks(newShortlinks)
        }}
        removeShortlink={(index) => {
          const newShortlinks = [...shortlinks]
          newShortlinks.splice(index, 1)
          setShortlinks(newShortlinks)
        }}
      />

      <ShortlinkShareDialog
        show={showShortlinkShare}
        toggle={setShowShortlinkShare}
        form={form}
        code={shareCode}
        addShare={(code, emails) => {
          const newShortlinkShares = [...shortlinkShares, { code, emails }]
          setShortlinkShares(newShortlinkShares)
        }}
      />
    </FormContextProvider>
  )
}
