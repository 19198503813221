import React, { useState, useMemo } from 'react'
import { get } from 'lodash'
import styled from 'styled-components'
import { Link } from '@reach/router'
import SVG from 'react-inlinesvg'
import Filter from './ListFilter'
import Table from './ListTable'
import {
  PageTitle,
  PageContent,
  PageLoading,
  DateRangePicker,
  useDateRangePickerState,
  directTableContainerStyles,
} from '../shared/elements'
import { useApiLoad } from '../shared/hooks'
import * as paths from '../../utils/paths'
import {
  toQuery,
  formatStatObject,
  formatStatSumObject,
} from '../../utils/helpers'

const Container = styled.div`
  ${directTableContainerStyles}
`

export default function Publishers(props) {
  const dateRangePickerState = useDateRangePickerState()
  const { startDate, defaultStartDate, endDate, defaultEndDate } =
    dateRangePickerState

  const [publishers, setPublishers] = useState(null)
  const [stats, setStats] = useState(null)
  const [filterQuery, setFilterQuery] = useState('')
  const [order, setOrder] = useState('name')
  const [sort, setSort] = useState('asc')

  const query = toQuery({
    startDate: (startDate || defaultStartDate).toISOString(),
    endDate: (endDate || defaultEndDate).toISOString(),
  })
  useApiLoad(
    `/advertiser/publishers?${query}`,
    publishers === null,
    (response) => {
      setPublishers(response?.data?.publishers)
      setStats(response?.data?.stats)
    }
  )

  const shownPublishers = useMemo(() => {
    if (!publishers || !stats) {
      return []
    }

    return publishers
      .map((publisher) => {
        const stat = stats?.find(
          (stat) => stat.affiliate_id === publisher.tuneAffiliateId
        )

        return {
          ...publisher,
          stats: formatStatObject(stat),
        }
      })
      .filter(
        (publisher) =>
          !filterQuery ||
          publisher.name.toLowerCase().indexOf(filterQuery.toLowerCase()) !== -1
      )
      .sort((publisherA, publisherB) => {
        const a = get(publisherA, order)
        const b = get(publisherB, order)
        const asc = sort === 'asc'

        if (a < b) {
          return asc ? -1 : 1
        } else if (a > b) {
          return asc ? 1 : -1
        } else {
          return 0
        }
      })
  }, [publishers, stats, filterQuery, order, sort])

  const totalStats = useMemo(() => formatStatSumObject(stats), [stats])

  const reloadData = () => {
    setPublishers(null)
  }

  const loading = publishers === null
  if (loading) {
    return <PageLoading title="All partners" crumbs={{ Partners: null }} />
  }

  return (
    <Container>
      <PageTitle title="All partners" crumbs={{ Partners: null }} />

      <PageContent loading={loading}>
        <div className="date-range-picker">
          <DateRangePicker state={dateRangePickerState} onChange={reloadData} />
        </div>

        <div className="panel">
          <div className="header">
            <Link
              to={paths.newPublisherPath()}
              className="add inline-flex-center"
            >
              Add partner <SVG src="/images/icons/plus.svg" />
            </Link>
            <Filter
              {...{
                filterQuery,
                setFilterQuery,
              }}
            />
          </div>
          <Table
            {...{
              publishers: shownPublishers,
              totalStats,
              order,
              setOrder,
              sort,
              setSort,
            }}
          />
        </div>
      </PageContent>
    </Container>
  )
}
