import React from 'react'
import styled from 'styled-components'
import { TextField } from '../shared/fields'

const Container = styled.div`
  > .form-group {
    margin-top: -16px;
    margin-bottom: 64px;

    .form-control {
      padding-left: 0;
      padding-right: 0;
      background: transparent;
      border-bottom: 3px solid var(--text-secondary-light);
      border-radius: 0;
      font-size: 20px;
      font-weight: bold;
      outline: none;
      box-shadow: none;

      ::placeholder {
        color: var(--text-secondary-light);
      }

      &:focus {
        border-bottom: 3px solid var(--text-primary);
      }
    }
  }
`

export default function ContractCode(props) {
  const { form } = props

  return (
    <Container>
      <TextField
        form={form}
        name="code"
        placeholder="Campaign name"
        wrapperClassName="mt--10 mb-60"
        validation={{ required: true }}
      />
    </Container>
  )
}
