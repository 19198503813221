import React, { useState, useContext } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Form from '../contracts/FormNew'
import { useApiLoad } from '../shared/hooks'
import { appContext } from '../shared/contexts'
import {
  PageContent,
  PageLoading,
  AddPaymentMethodDialog,
  directFormStyles,
} from '../shared/elements'
import { getRequest, postRequest } from '../../services/api'
import { getCurrentWebsite } from '../../services/auth'
import * as paths from '../../utils/paths'
import { capitalize } from '../../utils/helpers'

const Container = styled.div`
  ${directFormStyles}

  .form-page-content {
    max-width: 800px;
    margin: 0 auto;
  }
`

export default function PublisherNew(props) {
  const { showMessage } = useContext(appContext)

  const [publishers, setPublishers] = useState(null)
  const [conversionRuleSets, setConversionRuleSets] = useState(null)
  const [shortlinkCode, setShortlinkCode] = useState(null)
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)

  useApiLoad(
    '/advertiser/contracts/form',
    paymentMethods === null,
    (response) => {
      setPublishers(response?.data?.publishers)
      setConversionRuleSets(response?.data?.conversionRuleSets)
      setShortlinkCode(response?.data?.shortlinkCode)
      setPaymentMethods(response?.data?.paymentMethods)
    }
  )

  const reloadPaymentMethods = async () => {
    const response = await getRequest('/advertiser/contracts/form')
    setPaymentMethods(response?.data?.paymentMethods)
  }

  const onSubmit = async (values) => {
    const result = await postRequest(`/advertiser/contracts`, {
      contract: values,
    })

    if (result?.data?.success) {
      navigate(
        paths.publisherContractShortlinksPath(
          result.data.publisherUuid,
          result.data.contractUuid
        )
      )
    } else {
      console.error(result)
      showMessage(result?.message)
    }
  }

  const currentWebsite = getCurrentWebsite()

  const contract = {
    code: capitalize(currentWebsite),
    url: `https://${currentWebsite}/`,
    shortlinkCode,
  }

  const loading = paymentMethods === null
  if (loading) {
    return <PageLoading title={false} />
  }

  return (
    <Container>
      <PageContent className="form-page-content">
        <Form
          {...{
            cancelPath: paths.publishersPath(),
            contract,
            newPublisher: true,
            publishers,
            conversionRuleSets,
            paymentMethods,
            showAddPaymentMethod,
            setShowAddPaymentMethod,
            currentPage,
            setCurrentPage,
            onSubmit,
          }}
        />
      </PageContent>

      <AddPaymentMethodDialog
        show={showAddPaymentMethod}
        toggle={setShowAddPaymentMethod}
        onDone={reloadPaymentMethods}
      />
    </Container>
  )
}
