import React from 'react'
import { getCurrentUser } from '../../services/auth'
import { formatDatetime, formatMoney } from '../../utils/helpers'
import { invoiceUrl } from '../../utils/paths'

export default function InvoiceList(props) {
  const { invoices } = props

  const invoiceKeys = Object.keys(invoices)

  return (
    <table className="table table-striped table-responsive">
      <thead>
        <tr>
          <th>Statement</th>
          <th>Date/Time</th>
          <th>Amount</th>
          <th>Invoice</th>
        </tr>
      </thead>
      <tbody>
        {invoiceKeys.map((invoiceKey) => (
          <Invoice key={invoiceKey} invoice={invoices[invoiceKey]} />
        ))}
      </tbody>
    </table>
  )
}

const Invoice = (props) => {
  const { invoice } = props

  const currentUser = getCurrentUser()

  const currencies = Object.keys(invoice.totals)

  return (
    <>
      {currencies.map((currency, index) => (
        <tr key={`${invoice.uuid}-${currency}`}>
          {index === 0 ? (
            <>
              <td>{invoice.period}</td>
              <td>{formatDatetime(invoice.createdAt)}</td>
              <td>{formatMoney(invoice.totals[currency], currency)}</td>
              <td>
                {currentUser.advertiserUuid && (
                  <a
                    href={invoiceUrl(currentUser.advertiserUuid, invoice.uuid)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    INV#{invoice.hash.substr(0, 8).toUpperCase()}
                  </a>
                )}
              </td>
            </>
          ) : (
            <>
              <td></td>
              <td></td>
              <td>
                {currency} {invoice.totals[currency]}
              </td>
              <td></td>
            </>
          )}
        </tr>
      ))}
    </>
  )
}
