import React, { useState, useEffect, useCallback, useRef } from 'react'
import SVG from 'react-inlinesvg'
import { MaskField, SelectField, DateField, Radio } from '../shared/fields'
import { Collapse, CollapseToggle } from '../shared/elements'
import {
  currencies,
  periodTypes,
  booleanTypes,
  validates,
} from '../../utils/constants'
import { getValue } from '../../utils/helpers'

export default function ContractTerms(props) {
  const { form } = props
  const { getValues } = form

  const [commissionModel, setCommissionModel] = useState(null)

  const onCommissionModelChange = useCallback(() => {
    const value = getValue(getValues, 'data.commissionModel')
    setCommissionModel(value)
  }, [getValues])

  useEffect(() => {
    if (getValue(getValues, 'data.commissionModel')) {
      onCommissionModelChange()
    }
  }, [getValues, onCommissionModelChange])

  const advancedOptionsCollapseRef = useRef()

  return (
    <>
      <h5>Campaign period</h5>

      <div className="row">
        <div className="col-md-6 col-lg-4">
          <DateField
            form={form}
            validation={validates.required}
            name="data.startsAt"
            label="Campaign start"
          />
        </div>

        <div className="col-md-6 col-lg-4">
          <DateField
            form={form}
            validation={validates.required}
            name="data.endsAt"
            label="Campaign end"
          />
        </div>
      </div>

      <h5 className="mt-1">Campaign type</h5>
      <Radio
        form={form}
        name="data.commissionModel"
        value="CPS"
        Label={(props) => (
          <>
            <strong>Cost per sale</strong>{' '}
            <span className="muted">(E-commerce default)</span>
            <div className="hint">
              A percentage of each sale tracked to this campaign. Example: 10%
              of checkout subtotal.
            </div>
          </>
        )}
        onChange={onCommissionModelChange}
      />
      <div className={`row ml-4 ${commissionModel === 'CPS' ? '' : 'd-none'}`}>
        <div className="col-md-6 col-lg-4">
          <MaskField
            form={form}
            name="data.commissionPercentage"
            label="Partner fee percentage"
            mask={Array(2).fill(/\d/)}
          />
        </div>
        <div className="col-md-6 col-lg-3">
          <SelectField
            form={form}
            validation={validates.required}
            name="data.currency"
            label="Currency"
            search={false}
            options={currencies}
          />
        </div>
      </div>

      <Radio
        form={form}
        name="data.commissionModel"
        value="CPA"
        Label={(props) => (
          <>
            <strong>Cost per action</strong>
            <div className="hint">
              A fixed fee based on a "Per Action" model. Example: $15 for each
              user that subscribes to a service.
            </div>
          </>
        )}
        onChange={onCommissionModelChange}
      />
      <div className={`row ml-4 ${commissionModel === 'CPA' ? '' : 'd-none'}`}>
        <div className="col-md-6 col-lg-4">
          <MaskField
            form={form}
            name="data.commissionPrice"
            label="Partner fee fixed"
            mask={Array(20).fill(/\d|\./)}
          />
        </div>
        <div className="col-md-6 col-lg-3">
          <SelectField
            form={form}
            validation={validates.required}
            name="data.currency"
            label="Currency"
            search={false}
            options={currencies}
          />
        </div>
      </div>

      <Radio
        form={form}
        name="data.commissionModel"
        value="CPC"
        Label={(props) => (
          <>
            <strong>Cost per click</strong>
            <div className="hint">
              Partner fee is calculated from the number of clicks the Partner
              generates. Example: $0.50 per click from Partner.
            </div>
          </>
        )}
        onChange={onCommissionModelChange}
      />
      <div className={`row ml-4 ${commissionModel === 'CPC' ? '' : 'd-none'}`}>
        <div className="col-md-6 col-lg-4">
          <MaskField
            form={form}
            name="data.commissionPrice"
            label="Partner fee fixed"
            mask={Array(20).fill(/\d|\./)}
          />
        </div>

        <div className="col-md-6 col-lg-3">
          <SelectField
            form={form}
            validation={validates.required}
            name="data.currency"
            label="Currency"
            search={false}
            options={currencies}
          />
        </div>
      </div>

      <Radio
        form={form}
        name="data.commissionModel"
        value="NoCommission"
        Label={(props) => (
          <>
            <strong>No partner fee</strong>
            <div className="hint">
              Choose this option if no fee should be calculated on this offer.
            </div>
          </>
        )}
        onChange={onCommissionModelChange}
      />

      <Collapse control={advancedOptionsCollapseRef}>
        <CollapseToggle
          controls={advancedOptionsCollapseRef}
          className="a mt-1"
        >
          <h5 className="m-0">
            Advanced settings
            <SVG
              src="/images/icons/arrow-down.svg"
              width={14}
              className="ml-2"
            />
          </h5>
        </CollapseToggle>
        <div className="mt-35">
          <div className="row">
            <div className="col-md-6 col-xl-4">
              <MaskField
                form={form}
                validation={validates.required}
                name="data.trackingPeriod"
                label="Tracking period in days"
                hint="The maximum number of days after the initial click, in which a transaction/sale can be attributed to this campaign"
                mask={Array(3).fill(/\d/)}
              />
            </div>
            <div className="col-md-6 col-xl-3 d-none">
              <SelectField
                form={form}
                name="data.trackingPeriodType"
                search={false}
                options={periodTypes}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-xl-4">
              <MaskField
                form={form}
                validation={validates.required}
                name="data.transactionDeclinePeriod"
                label="Transaction decline period"
                hint="The period of time when a transaction/sale can be rejected"
                mask={Array(3).fill(/\d/)}
              />
            </div>
            <div className="col-md-6 col-xl-3">
              <SelectField
                form={form}
                name="data.transactionDeclinePeriodType"
                search={false}
                options={periodTypes}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <SelectField
                form={form}
                name="data.deeplinksEnabled"
                label="Deeplinks"
                hint="Allow partners to link to specific pages on your website"
                search={false}
                options={booleanTypes}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <SelectField
                form={form}
                name="data.multipleConversionsEnabled"
                label="Multiple conversions"
                hint="Enable multiple conversions to be tracked per user in the same session. A new session is created each time a user clicks on a tracking link"
                wrapperClassName="mb-0"
                search={false}
                options={booleanTypes}
              />
            </div>
          </div>
        </div>
      </Collapse>
    </>
  )
}
