import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import {
  SortColumn,
  directTableStyles,
  directTableInteractiveStyles,
} from '../shared/elements'
import { navigateTo } from '../../utils/helpers'
import {
  formatStatValue,
  formatStatPercentage,
  formatStatMoney,
} from '../../utils/helpers/stats'
import * as paths from '../../utils/paths'

const Container = styled.div`
  ${directTableStyles}
  ${directTableInteractiveStyles}
`

export default function PublishersTable(props) {
  const { publishers, totalStats, order, setOrder, sort, setSort } = props

  const sortProps = {
    order,
    setOrder,
    sort,
    setSort,
  }

  if (publishers === null) {
    return (
      <Container>
        <table className="table table-borderless">
          <thead>
            <tr>
              <td>Loading...</td>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td className="text-center text-primary py-5">
                <div className="spinner-border" role="status" />
              </td>
            </tr>
          </tfoot>
        </table>
      </Container>
    )
  }

  return (
    <Container>
      <table className="table table-borderless">
        <thead>
          <tr>
            <td className="spacer" />
            <SortColumn
              element="th"
              field="name"
              label="Partner"
              defaultSort="asc"
              {...sortProps}
            />
            <SortColumn
              className="text-right"
              field="stats.saleAmount"
              label="Total sales"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.conversionCount"
              label="Conversions"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.conversionRate"
              label="CR"
              defaultSort="desc"
              title="Conversion rate"
              {...sortProps}
            />
            <SortColumn
              className="text-right"
              field="stats.avgSaleAmount"
              label="AOV"
              defaultSort="desc"
              title="Average order value"
              {...sortProps}
            />
            <SortColumn
              field="stats.grossClicks"
              label="Clicks"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.uniqueClicks"
              label="Unique clicks"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.nonUniqueClicks"
              label="Non-unique clicks"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.rejectedClicks"
              label="R. clicks"
              title="Rejected clicks"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.suspiciousClicks"
              label="S. clicks"
              title="Suspicious clicks"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              className="text-right"
              field="stats.payout"
              label="Traffic cost"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              className="text-right"
              field="stats.acquisitionCost"
              label="Customer acquisition cost"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              className="text-right"
              field="stats.profit"
              label="Profit"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.newCustomerRate"
              label="New customer rate"
              defaultSort="desc"
              {...sortProps}
            />
            <SortColumn
              field="stats.itemsPerOrder"
              label="Items per order"
              defaultSort="desc"
              {...sortProps}
            />
            <td className="spacer" />
          </tr>
        </thead>

        <tbody>
          {publishers.map((record) => (
            <tr
              key={record.uuid}
              onClick={(event) =>
                navigateTo(event, paths.publisherPath(record.uuid))
              }
            >
              <td className="spacer" />
              <th>
                <span className="hover-icon">
                  <SVG src="/images/icons/arrow-point-right.svg" />
                </span>
                <span className="main-column">{record.name}</span>
              </th>
              <td className="with-separator text-right">
                {formatStatMoney(
                  record.stats?.saleAmount,
                  record.stats?.currency
                )}
              </td>
              <td className="with-separator">
                {formatStatValue(record.stats?.conversionCount)}
              </td>
              <td className="with-separator">
                {formatStatPercentage(record.stats?.conversionRate)}
              </td>
              <td className="with-separator text-right">
                {formatStatMoney(
                  record.stats?.avgSaleAmount,
                  record.stats?.currency
                )}
              </td>
              <td className="with-separator">
                {formatStatValue(record.stats?.grossClicks)}
              </td>
              <td className="with-separator">
                {formatStatValue(record.stats?.uniqueClicks)}
              </td>
              <td className="with-separator">
                {formatStatValue(record.stats?.nonUniqueClicks)}
              </td>
              <td className="with-separator">
                {formatStatValue(record.stats?.rejectedClicks)}
              </td>
              <td className="with-separator">
                {formatStatValue(record.stats?.suspiciousClicks)}
              </td>
              <td className="with-separator text-right">
                {formatStatMoney(record.stats?.payout, record.stats?.currency)}
              </td>
              <td className="with-separator text-right">
                {formatStatMoney(
                  record.stats?.acquisitionCost,
                  record.stats?.currency
                )}
              </td>
              <td className="with-separator text-right">
                {formatStatMoney(record.stats?.profit, record.stats?.currency)}
              </td>
              <td className="with-separator">
                {formatStatPercentage(record.stats?.newCustomerRate, true)}
              </td>
              <td className="without-separator">
                {formatStatValue(record.stats?.itemsPerOrder, true)}
              </td>
              <td className="spacer" />
            </tr>
          ))}

          <tr className="total">
            <td className="spacer" />
            <th>
              <span>Total</span>
            </th>
            <td className="with-separator text-right">
              {formatStatMoney(totalStats?.saleAmount, totalStats?.currency)}
            </td>
            <td className="with-separator">
              {formatStatValue(totalStats?.conversionCount)}
            </td>
            <td className="with-separator">
              {formatStatPercentage(totalStats?.conversionRate)}
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(totalStats?.avgSaleAmount, totalStats?.currency)}
            </td>
            <td className="with-separator">
              {formatStatValue(totalStats?.grossClicks)}
            </td>
            <td className="with-separator">
              {formatStatValue(totalStats?.uniqueClicks)}
            </td>
            <td className="with-separator">
              {formatStatValue(totalStats?.nonUniqueClicks)}
            </td>
            <td className="with-separator">
              {formatStatValue(totalStats?.rejectedClicks)}
            </td>
            <td className="with-separator">
              {formatStatValue(totalStats?.suspiciousClicks)}
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(totalStats?.payout, totalStats?.currency)}
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(
                totalStats?.acquisitionCost,
                totalStats?.currency
              )}
            </td>
            <td className="with-separator text-right">
              {formatStatMoney(totalStats?.profit, totalStats?.currency)}
            </td>
            <td className="with-separator">
              {formatStatPercentage(totalStats?.newCustomerRate)}
            </td>
            <td className="without-separator">
              {formatStatValue(totalStats?.itemsPerOrder, true)}
            </td>
            <td className="spacer" />
          </tr>
        </tbody>
      </table>
    </Container>
  )
}
