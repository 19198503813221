import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useForm } from 'react-hook-form'
import { Dialog, ShortlinkCode } from '../shared/elements'
import { TextField } from '../shared/fields'
import { getRequest } from '../../services/api'
import { getCurrentWebsite } from '../../services/auth'
import { getValue } from '../../utils/helpers'

const Form = styled.form`
  .new-form-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .edit-form-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-link {
      margin-left: -12px;
      font-size: 14px;
      font-weight: normal;
      text-decoration: none;
    }

    svg {
      width: 12px;
      height: 12px;
      margin-top: -2px;
      margin-right: 8px;
    }
  }
`

export default function FormDialog(props) {
  const {
    show,
    toggle,
    form,
    contract,
    shortlink,
    shortlinkIndex,
    onShare,
    onDone,
    addShortlink,
    updateShortlink,
    removeShortlink,
  } = props

  const [newCode, setNewCode] = useState('')

  const isNew = !shortlink
  const currentWebsite = getCurrentWebsite()

  const shortlinkForm = useForm({
    mode: 'onChange',
    defaultValues: {
      name: ' ',
      targetUrl: ' ',
      publisher: contract.publisher?.name,
      contract: ' ',
      source: currentWebsite,
      ...shortlink,
    },
  })

  const { reset } = shortlinkForm
  useEffect(() => {
    const code = getValue(form.getValues, 'code')
    const website = getCurrentWebsite()
    reset({
      name: ' ',
      targetUrl: ' ',
      publisher: contract.publisher?.name,
      contract: code || 'Campaign name',
      source: website,
      ...shortlink,
    })
    if (!show) {
      setNewCode('')
    }
  }, [reset, form.getValues, show, contract, shortlink])

  const remove = async () => {
    if (window.confirm('Are you sure you want to delete this smart link?')) {
      removeShortlink(shortlinkIndex)

      toggle(false)
      if (onDone) {
        onDone()
      }
    }
  }

  const onSubmit = async (values) => {
    if (isNew) {
      const newCode = await getRequest('/advertiser/shortlinks/code')
      values.code = newCode.data.code
      addShortlink(values)
      setNewCode(values.code)
    } else {
      updateShortlink(shortlinkIndex, values)
      onClose()
    }
  }

  const onClose = () => {
    toggle(false)
    if (onDone) {
      onDone()
    }
  }

  return (
    <Dialog
      show={show}
      toggle={toggle}
      dialogClassName={newCode ? 'bg-gray' : ''}
    >
      {!newCode ? (
        <>
          <h4 className="mt--25 mb-30">{isNew ? 'New' : 'Edit'} smart link</h4>
          <Form onSubmit={shortlinkForm.handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6">
                <TextField
                  form={shortlinkForm}
                  validation={{ required: true }}
                  name="name"
                  label="Smart link name"
                />
              </div>

              <div className="col-md-6">
                <TextField
                  form={shortlinkForm}
                  name="publisher"
                  label="Partner"
                  disabled={true}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <TextField
                  form={shortlinkForm}
                  name="contract"
                  label="Campaign"
                  disabled={true}
                />
              </div>

              <div className="col-md-6">
                <TextField
                  form={shortlinkForm}
                  name="source"
                  label="Source"
                  disabled={true}
                />
              </div>
            </div>

            <h5 className="mb-4">Options</h5>
            <TextField
              form={shortlinkForm}
              name="targetUrl"
              label="Destination URL"
              hint="Use this if you want visitors to land on another page than your homepage."
            />

            {isNew ? (
              <div className="new-form-actions">
                <button className="btn btn-primary px-5">Done</button>
              </div>
            ) : (
              <div className="edit-form-actions">
                <button
                  type="button"
                  onClick={remove}
                  className="btn btn-link text-danger"
                >
                  <SVG src="/images/icons/trash.svg" />
                  Delete this smart link
                </button>
                <button className="btn btn-primary px-5">Save</button>
              </div>
            )}
          </Form>
        </>
      ) : (
        <>
          <h2 className="text-center">Good job!</h2>
          <h5 className="text-center">Here is your smart link</h5>
          <div className="panel mt-4 mb-5">
            <ShortlinkCode
              contract={contract}
              code={newCode}
              share={() => {
                onShare(newCode)
                toggle(false)
              }}
            />
            <div className="text-hint">
              Copy or send this link to the Partner that will be sending traffic
              to you
            </div>
          </div>
          <div className="text-right">
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={onClose}
            >
              Done
            </button>
          </div>
        </>
      )}
    </Dialog>
  )
}
