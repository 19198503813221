import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Form from './Form'
import { appContext } from '../shared/contexts'
import { PageTitle, PageContent, directFormStyles } from '../shared/elements'
import { postRequest } from '../../services/api'
import * as paths from '../../utils/paths'

const Container = styled.div`
  ${directFormStyles}
`

export default function NewShortlink(props) {
  const { publisherUuid, contractUuid } = props

  const { showMessage } = useContext(appContext)

  const onSubmit = async (values) => {
    const result = await postRequest(`/advertiser/shortlinks`, {
      shortlink: {
        ...values,
        publisherUuid,
        contractUuid,
      },
    })

    if (result?.data?.success) {
      navigate(
        paths.publisherContractShortlinksPath(publisherUuid, contractUuid)
      )
    } else {
      console.error(result)
      showMessage(result?.message)
    }
  }

  return (
    <Container>
      <PageTitle
        title="New smart link"
        backPath={paths.publisherContractShortlinksPath(
          publisherUuid,
          contractUuid
        )}
      />

      <PageContent>
        <div className="panel px-4 py-5 mt-20">
          <Form onSubmit={onSubmit} />
        </div>
      </PageContent>
    </Container>
  )
}
