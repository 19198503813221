import { emailRegex } from '../../utils/constants'

export const validate = (form, emails, page) => {
  const values = form.getValues()
  const fields = []

  let fieldset = ''
  if (page === 0) fieldset = 'publisher'
  if (page === 1) fieldset = 'terms'
  if (page === 2) fieldset = 'contact'

  if (fieldset === 'publisher') {
    const noEmails = emails.every((email) => !email)
    const incorrectEmail = emails.find(
      (email) => email && !email.match(emailRegex)
    )

    if (noEmails) {
      return 'No partner e-mail provided'
    } else if (incorrectEmail) {
      return `Incorrect partner e-mail: '${incorrectEmail}'`
    }

    fields.push('publisher.name')
  }

  if (fieldset === 'terms') {
    fields.push(
      'data.startsAt',
      'data.endsAt',
      'data.commissionModel',
      'data.currency'
    )

    if (values.data.commissionModel === 'CPS') {
      fields.push('data.commissionPercentage')
    } else {
      fields.push('data.commissionPrice')
    }
  }

  if (fieldset === 'contract') {
    fields.push('code')
  }

  if (!fieldset) {
    return true
  }

  return form.trigger(fields)
}
