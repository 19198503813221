import React, { useState, useContext } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Form from './FormNew'
import { useApiLoad } from '../shared/hooks'
import { appContext } from '../shared/contexts'
import {
  PageContent,
  PageLoading,
  AddPaymentMethodDialog,
  directFormStyles,
} from '../shared/elements'
import { getRequest, postRequest } from '../../services/api'
import { getCurrentWebsite } from '../../services/auth'
import * as paths from '../../utils/paths'
import { toQuery, capitalize } from '../../utils/helpers'

const Container = styled.div`
  ${directFormStyles}

  .form-page-content {
    max-width: 800px;
    margin: 0 auto;
  }
`

export default function ContractNew(props) {
  const { publisherUuid } = props

  const { showMessage } = useContext(appContext)

  const [publisher, setPublisher] = useState(null)
  const [publishers, setPublishers] = useState(null)
  const [conversionRuleSets, setConversionRuleSets] = useState(null)
  const [shortlinkCode, setShortlinkCode] = useState(null)
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  useApiLoad(
    `/advertiser/contracts/form?${toQuery({
      publisherUuid,
    })}`,
    publisher === null,
    (response) => {
      setPublisher(response?.data?.publisher)
      setPublishers(response?.data?.publishers)
      setConversionRuleSets(response?.data?.conversionRuleSets)
      setShortlinkCode(response?.data?.shortlinkCode)
      setPaymentMethods(response?.data?.paymentMethods)
    }
  )

  const reloadPaymentMethods = async () => {
    const response = await getRequest(
      `/advertiser/contracts/form?${toQuery({
        publisherUuid,
      })}`
    )
    setPaymentMethods(response?.data?.paymentMethods)
  }

  const onSubmit = async (values) => {
    const result = await postRequest(`/advertiser/contracts`, {
      contract: values,
      publisherUuid,
    })

    if (result?.data?.success) {
      navigate(
        paths.publisherContractShortlinksPath(
          result.data.publisherUuid,
          result.data.contractUuid
        )
      )
    } else {
      console.error(result)
      showMessage(result?.message)
    }
  }

  const currentWebsite = getCurrentWebsite()

  const contract = {
    code: capitalize(currentWebsite),
    url: `https://${currentWebsite}/`,
    shortlinkCode,
    publisher,
  }

  const loading = publisher === null || paymentMethods === null
  if (loading) {
    return <PageLoading title={false} />
  }

  return (
    <Container>
      <PageContent className="form-page-content">
        <Form
          {...{
            cancelPath: paths.publisherPath(publisherUuid),
            contract,
            newPublisher: false,
            publishers,
            conversionRuleSets,
            paymentMethods,
            setPaymentMethods,
            showAddPaymentMethod,
            setShowAddPaymentMethod,
            currentPage,
            setCurrentPage,
            onSubmit,
          }}
        />
      </PageContent>

      <AddPaymentMethodDialog
        show={showAddPaymentMethod}
        toggle={setShowAddPaymentMethod}
        onDone={reloadPaymentMethods}
      />
    </Container>
  )
}
