import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { Link } from '@reach/router'
import * as paths from '../../utils/paths'

const Container = styled.div.attrs((props) => ({
  className: 'panel',
}))`
  padding-top: 12px;

  .header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 50px;
      height: 50px;
      margin-right: 16px;
      border-radius: 10px;
      color: #eef1f2;
    }

    h5 {
      position: relative;
      top: 6px;
    }

    .partner-type {
      font-size: 12px;
      letter-spacing: -0.2px;
    }

    .btn-details {
      position: absolute;
      bottom: -12px;
      right: 0px;
      color: var(--text-link);
      font-family: var(--body-font);
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        color: var(--text-link-hover);
      }
    }
  }

  .content {
    margin: -12px 0 -19px;

    .label {
      font-size: 12px;
      white-space: nowrap;
    }

    .value {
      font-size: 18px;
      font-weight: 500;
      white-space: nowrap;
    }

    .new-partner {
      &:not(:last-of-type) {
        border-bottom: 1px solid #eee;
      }

      > a {
        display: flex;
        flex-direction: row;
        padding: 8px 0 7px;
        color: inherit;
        font-weight: normal;
        align-items: center;
        justify-content: space-between;
      }

      img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
        object-fit: contain;
      }

      .partner-title {
        font-weight: bold;
      }
    }

    .arrow {
      margin-top: -2px;
      height: 12px;
      transform: rotate(180deg);
    }
  }
`

export default function PartnerSuggestions(props) {
  const { publishers } = props

  if (!publishers || publishers.length === 0) {
    return null
  }

  return (
    <Container>
      <div className="header">
        <SVG src="/images/icons/add.svg" />
        <div>
          <h5>Add partner</h5>
          <div className="partner-type">
            Increase sales by adding new partners
          </div>
        </div>
        <button className="a btn-details">See all</button>
      </div>
      <hr />
      <div className="content">
        {publishers.slice(0, 3).map((publisher) => (
          <div key={publisher.uuid} className="new-partner">
            <Link to={paths.publisherPath(publisher.uuid)}>
              <span>
                {publisher.logoUrl && (
                  <img src={publisher.logoUrl} alt={`${publisher.name} logo`} />
                )}
                <span className="partner-title">{publisher.name}</span>
                {' - '}
                <span className="partner-stats">
                  {publisher.sourceAttraction}
                </span>
              </span>
              <SVG src="/images/icons/arrow-left.svg" className="arrow" />
            </Link>
          </div>
        ))}
      </div>
    </Container>
  )
}
