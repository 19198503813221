import React, { useState, useMemo } from 'react'
import { get } from 'lodash'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import Filter from './ListFilter'
import Table from './ListTable'
import FormDialog from './FormDialog'
import ShareDialog from './ShareDialog'
import {
  PageTitle,
  PageContent,
  PageLoading,
  DateRangePicker,
  useDateRangePickerState,
  directTableContainerStyles,
} from '../shared/elements'
import { useApiLoad } from '../shared/hooks'
import * as paths from '../../utils/paths'
import {
  toQuery,
  formatStatObject,
  formatStatSumObject,
} from '../../utils/helpers'

const Container = styled.div`
  ${directTableContainerStyles}

  .h5 {
    margin-bottom: 0;
    font-weight: bold;
  }

  .add {
    font-weight: bold;
  }

  .hint {
    margin-bottom: 8px;
    color: var(--text-secondary);
    font-size: 13px;
  }
`

export default function Shortlinks(props) {
  const { publisherUuid, contractUuid } = props

  const [showShare, setShowShare] = useState(false)
  const [shareCode, setShareCode] = useState(null)
  const showCodeShare = (code) => () => {
    setShareCode(code)
    setShowShare(true)
  }

  const [showForm, setShowForm] = useState(false)
  const [formShortlink, setFormShortlink] = useState(null)
  const showShortlinkForm = (shortlink) => () => {
    setFormShortlink(shortlink)
    setShowForm(true)
  }

  const dateRangePickerState = useDateRangePickerState()
  const { startDate, defaultStartDate, endDate, defaultEndDate } =
    dateRangePickerState

  const [publisher, setPublisher] = useState(null)
  const [contract, setContract] = useState(null)
  const [shortlinks, setShortlinks] = useState(null)
  const [stats, setStats] = useState(null)
  const [filterQuery, setFilterQuery] = useState('')
  const [order, setOrder] = useState('createdAt')
  const [sort, setSort] = useState('desc')

  const query = toQuery({
    startDate: (startDate || defaultStartDate).toISOString(),
    endDate: (endDate || defaultEndDate).toISOString(),
  })
  useApiLoad(
    `/advertiser/shortlinks/${publisherUuid}/${contractUuid}?${query}`,
    shortlinks === null,
    (response) => {
      setPublisher(response?.data?.publisher)
      setContract(response?.data?.contract)
      setShortlinks(response?.data?.shortlinks)
      setStats(response?.data?.stats)
    }
  )

  const reloadShortlinks = () => {
    setShortlinks(null)
  }

  const shownShortlinks = useMemo(() => {
    if (!shortlinks || !stats) {
      return []
    }

    return shortlinks
      .map((shortlink) => {
        const stat = stats?.find(
          (stat) => stat.affiliate_info3 === shortlink.code
        )

        return {
          ...shortlink,
          stats: formatStatObject(stat),
        }
      })
      .filter(
        (shortlink) =>
          !filterQuery ||
          shortlink.name.toLowerCase().indexOf(filterQuery.toLowerCase()) !== -1
      )
      .sort((shortlinkA, shortlinkB) => {
        const a = get(shortlinkA, order)
        const b = get(shortlinkB, order)
        const asc = sort === 'asc'

        if (a < b) {
          return asc ? -1 : 1
        } else if (a > b) {
          return asc ? 1 : -1
        } else {
          return 0
        }
      })
  }, [shortlinks, stats, filterQuery, order, sort])

  const totalStats = useMemo(() => formatStatSumObject(stats), [stats])

  const reloadData = () => {
    setShortlinks(null)
  }

  const loading =
    publisher === null ||
    contract === null ||
    shortlinks === null ||
    stats === null
  if (loading) {
    return <PageLoading />
  }

  return (
    <Container>
      <PageTitle
        title={contract.code}
        crumbs={{
          Partners: paths.publishersPath(),
          [publisher.name]: paths.publisherPath(publisher?.uuid),
          [contract.code]: null,
        }}
      />

      <PageContent>
        <div className="date-range-picker">
          <DateRangePicker state={dateRangePickerState} onChange={reloadData} />
        </div>

        <div className="panel">
          <div className="h5">Smart links</div>
          <div className="hint">
            Smart links are the links that you provide to Partners. Create as
            many as you want in order to segment your traffic.
          </div>

          <div className="header">
            <button
              onClick={showShortlinkForm(null)}
              className="a add inline-flex-center"
            >
              Add smart link <SVG src="/images/icons/plus.svg" />
            </button>
            <Filter
              {...{
                filterQuery,
                setFilterQuery,
              }}
            />
          </div>
          <Table
            {...{
              contract,
              shortlinks: shownShortlinks,
              editShortlink: showShortlinkForm,
              totalStats,
              order,
              setOrder,
              sort,
              setSort,
              publisherUuid,
              contractUuid,
            }}
          />
        </div>

        <FormDialog
          show={showForm}
          toggle={setShowForm}
          contract={contract}
          shortlink={formShortlink}
          onShare={showCodeShare}
          onDone={reloadShortlinks}
        />

        <ShareDialog show={showShare} toggle={setShowShare} code={shareCode} />
      </PageContent>
    </Container>
  )
}
