import React, { useState, useEffect, useCallback, useRef } from 'react'
import moment from 'moment'
import SVG from 'react-inlinesvg'
import {
  MaskField,
  SelectField,
  DateField,
  BlockRadio,
  CountrySelect,
} from '../shared/fields'
import { useRerender } from '../shared/hooks'
import { Collapse, CollapseToggle } from '../shared/elements'
import { currencies, validates } from '../../utils/constants'
import { getCountryName } from '../../utils/countries'
import { getValue } from '../../utils/helpers'

const formatCollapsedHintPeriod = (startsAt, endsAt) => {
  let start = moment(startsAt).format('DD/MM/YYYY')
  if (start === moment().format('DD/MM/YYYY')) {
    start = 'Today'
  }

  let end = moment(endsAt).format('DD/MM/YYYY')
  if (!endsAt) {
    end = 'Never'
  }

  return `${start} – ${end}`
}

const formatCollapsedHintCountry = (countryCode) => {
  if (countryCode) {
    return getCountryName(countryCode)
  } else {
    return null
  }
}

export default function ContractFormNewTerms(props) {
  const { form } = props
  const { getValues } = form

  const [commissionModel, setCommissionModel] = useState(null)

  const onCommissionModelChange = useCallback(() => {
    const value = getValue(getValues, 'data.commissionModel')
    setCommissionModel(value)
  }, [getValues])

  useEffect(() => {
    if (getValue(getValues, 'data.commissionModel')) {
      onCommissionModelChange()
    }
  }, [getValues, onCommissionModelChange])

  const collapse1 = useRef()
  const collapse2 = useRef()
  const collapse3 = useRef()
  const collapse4 = useRef()

  const rerender = useRerender()

  return (
    <>
      <div className="panel px-35 mb-4">
        <Collapse control={collapse1} initiallyOpen={true}>
          <CollapseToggle
            controls={collapse1}
            initiallyOpen={true}
            className="a mt-1"
          >
            <h5 className="m-0">
              <div>Campaign type</div>
              <SVG src="/images/icons/arrow-down.svg" width={14} />
            </h5>
          </CollapseToggle>

          <div>
            <p className="mt-3 mb-5">
              Choose the type of payout structure that you want to offer your
              Partner.
              <br />
              Don't worry, it can be changed later.
            </p>

            <div className="commission-types">
              <BlockRadio
                form={form}
                name="data.commissionModel"
                value="CPS"
                Label={(props) => (
                  <>
                    <h6>Cost per sale</h6>
                    <SVG
                      src="/images/icons/commission-model-percentage.svg"
                      width={54}
                      height={54}
                    />
                    <div className="hint">
                      Percentage of sales from products, E-commerce, webshop
                    </div>
                  </>
                )}
                onChange={onCommissionModelChange}
              />

              <BlockRadio
                form={form}
                name="data.commissionModel"
                value="CPA"
                Label={(props) => (
                  <>
                    <h6>Cost per action/lead</h6>
                    <SVG
                      src="/images/icons/commission-model-price.svg"
                      width={54}
                      height={54}
                    />
                    <div className="hint">
                      Fixed fee for leads, services, signups
                    </div>
                  </>
                )}
                onChange={onCommissionModelChange}
              />

              <BlockRadio
                form={form}
                name="data.commissionModel"
                value="CPC"
                Label={(props) => (
                  <>
                    <h6>Cost per click</h6>
                    <SVG
                      src="/images/icons/commission-model-click.svg"
                      width={54}
                      height={54}
                    />
                    <div className="hint">
                      Pay for each click the Partner sends to your website/app
                    </div>
                  </>
                )}
                onChange={onCommissionModelChange}
              />

              <BlockRadio
                form={form}
                name="data.commissionModel"
                value="NoCommission"
                Label={(props) => (
                  <>
                    <h6>No commission</h6>
                    <SVG
                      src="/images/icons/commission-model-zero.svg"
                      width={54}
                      height={54}
                    />
                    <div className="hint">E-commerce, webshops, products</div>
                  </>
                )}
                onChange={onCommissionModelChange}
              />
            </div>
          </div>
        </Collapse>
      </div>

      <div className="panel px-35 mb-4">
        <Collapse control={collapse2} initiallyOpen={true}>
          <CollapseToggle
            controls={collapse2}
            initiallyOpen={true}
            className="a mt-1"
          >
            <h5 className="m-0">
              <div>Partner payout & currency</div>
              <SVG src="/images/icons/arrow-down.svg" width={14} />
            </h5>
          </CollapseToggle>

          <div>
            <div className={`row ${commissionModel === 'CPS' ? '' : 'd-none'}`}>
              <div className="col-12 mt-3 mb-5">
                <b>Cost per sale:</b> Define the percentage your Partner will
                receive on a successfully referred sale.
              </div>
              <div className="col-md-6 col-lg-4">
                <MaskField
                  form={form}
                  name="data.commissionPercentage"
                  label="Partner fee percentage"
                  mask={Array(2).fill(/\d/)}
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <SelectField
                  form={form}
                  validation={validates.required}
                  name="data.currency"
                  label="Currency"
                  search={false}
                  options={currencies}
                />
              </div>
            </div>

            <div className={`row ${commissionModel === 'CPA' ? '' : 'd-none'}`}>
              <div className="col-12 mt-3 mb-5">
                <b>Cost per action/lead:</b> Define the fee your Partner will
                receive on a referred lead.
              </div>
              <div className="col-md-6 col-lg-4">
                <MaskField
                  form={form}
                  name="data.commissionPrice"
                  label="Partner fee fixed"
                  mask={Array(20).fill(/\d|\./)}
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <SelectField
                  form={form}
                  validation={validates.required}
                  name="data.currency"
                  label="Currency"
                  search={false}
                  options={currencies}
                />
              </div>
            </div>

            <div className={`row ${commissionModel === 'CPC' ? '' : 'd-none'}`}>
              <div className="col-12 mt-3 mb-5">
                <b>Cost per click:</b> Define the fee your Partner will receive
                on a successfully refer.
              </div>
              <div className="col-md-6 col-lg-4">
                <MaskField
                  form={form}
                  name="data.commissionPrice"
                  label="Partner fee fixed"
                  mask={Array(20).fill(/\d|\./)}
                />
              </div>
              <div className="col-md-6 col-lg-3">
                <SelectField
                  form={form}
                  validation={validates.required}
                  name="data.currency"
                  label="Currency"
                  search={false}
                  options={currencies}
                />
              </div>
            </div>

            <div
              className={`row ${
                commissionModel === 'NoCommission' ? '' : 'd-none'
              }`}
            >
              <div className="col-12 mt-3">
                <b>No commission:</b> Partner will receive no fee from referred
                sales or leads.
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="panel px-35 mb-4">
        <Collapse control={collapse3}>
          <CollapseToggle controls={collapse3} className="a mt-1">
            <h5 className="m-0">
              <div>
                Campaign period
                <span className="d-none d-md-inline">
                  {formatCollapsedHintPeriod(
                    getValues()?.data?.startsAt,
                    getValues()?.data?.endsAt
                  )}
                </span>
              </div>
              <SVG src="/images/icons/arrow-down.svg" width={14} />
            </h5>
          </CollapseToggle>

          <div>
            <div className="row">
              <div className="col-12 mt-3 mb-5">
                If no period is defined the campaign will run until paused or
                closed manually.
              </div>

              <div className="col-md-6 col-lg-4">
                <DateField
                  form={form}
                  onChange={rerender}
                  name="data.startsAt"
                  label="Starting date"
                />
              </div>

              <div className="col-md-6 col-lg-4">
                <DateField
                  form={form}
                  onChange={rerender}
                  name="data.endsAt"
                  label="Expire date"
                  allowBlank="Never"
                />
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="panel px-35 mb-4">
        <Collapse control={collapse4}>
          <CollapseToggle controls={collapse4} className="a mt-1">
            <h5 className="m-0">
              <div>
                Geographics
                <span className="d-none d-md-inline">
                  {formatCollapsedHintCountry(
                    getValues()?.data?.companyCountry
                  )}
                </span>
              </div>
              <SVG src="/images/icons/arrow-down.svg" width={14} />
            </h5>
          </CollapseToggle>

          <div>
            <div className="row">
              <div className="col-12 mt-3 mb-5">
                Define the geographic location this campaign is available in.
              </div>

              <div className="col-md-6">
                <CountrySelect
                  form={form}
                  onCountryChange={rerender}
                  validation={validates.required}
                  name="data.companyCountry"
                  label="Country"
                />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  )
}
