import React, { useState, useMemo } from 'react'
import { camelCase } from 'lodash'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  YAxis,
  Tooltip,
} from 'recharts'
import { Dropdown } from '../shared/elements'
import {
  formatStatTotal,
  formatChartDataFromStats,
} from '../../utils/helpers/chart'
import { formatStatValue } from '../../utils/helpers/stats'
import { capitalize } from '../../utils/helpers'
import { defaultCurrency } from '../../utils/constants'

const StatsPanel = styled.div`
  display: flex;
  flex-direction: column-reverse;
`

const StatsTotals = styled.div`
  .stat-value {
    margin-bottom: 4px;
    color: var(--text-primary);
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    white-space: nowrap;
  }

  .stat-label {
    color: var(--text-secondary-light);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.6px;
  }

  .click-type-filter {
    padding: 0;
    text-align: left;
    white-space: nowrap;

    svg {
      margin-top: -3px;
      margin-left: 3px;
      color: var(--text-secondary-light);
    }

    &.open {
      svg {
        margin-top: 0;
        transform: rotate(180deg);
      }
    }
  }

  .click-type-options {
    left: 0;
    width: 120px;

    button {
      width: 100%;
      text-align: left;

      &:hover {
        background: #eee;
      }
    }
  }
`

const chartHeight = 200

const getCurrency = (conversions) => {
  if (conversions && conversions.length) {
    return conversions[0].currency || defaultCurrency
  } else {
    return defaultCurrency
  }
}

export default function StatsChart(props) {
  const {
    conversions,
    totals,
    startDate,
    endDate,
    defaultStartDate,
    defaultEndDate,
  } = props

  const [clickType, setClickType] = useState('gross_clicks')

  const data = useMemo(() => {
    if (conversions) {
      return formatChartDataFromStats(
        conversions,
        {
          'Sales amount': 'sale_amount',
          Clicks: clickType,
        },
        startDate || defaultStartDate,
        endDate || defaultEndDate
      )
    } else {
      return null
    }
  }, [
    conversions,
    clickType,
    startDate,
    endDate,
    defaultStartDate,
    defaultEndDate,
  ])

  const dataPresent = conversions && totals && data

  const currency = getCurrency(conversions)

  return (
    <div className="panel">
      <div className="table-container">
        <div className="table-header">Stats</div>

        {dataPresent && (
          <StatsPanel className="px-2">
            <ResponsiveContainer width="100%" height={chartHeight}>
              <LineChart data={data} margin={{ top: 30, bottom: 10 }}>
                <CartesianGrid horizontal={false} />
                <YAxis yAxisId="amount" hide={true} />
                <YAxis yAxisId="clicks" hide={true} />
                <ReferenceLine y={0} yAxisId="clicks" />
                <Line
                  type="monotone"
                  dataKey="Clicks"
                  stroke="#fb9c31"
                  yAxisId="clicks"
                  strokeWidth={2}
                  dot={false}
                  activeDot={false}
                />
                <Line
                  type="monotone"
                  dataKey="Sales amount"
                  stroke="#78d4c0"
                  yAxisId="amount"
                  strokeWidth={6}
                  dot={false}
                  activeDot={false}
                />
                <Tooltip
                  formatter={(value) =>
                    Number(value).toFixed(3).replace(/0$/, '')
                  }
                  labelFormatter={(index) => data[index]?.label || ''}
                />
              </LineChart>
            </ResponsiveContainer>

            <StatsTotals className="row mt-5">
              <div className="col-sm-4 stat">
                <div className="stat-value">
                  {formatStatTotal(totals.saleAmount, currency)}
                </div>
                <div className="stat-label">Sales amount</div>
              </div>
              <div className="col-sm-4">
                <div className="stat-value">
                  {formatStatTotal(totals.revenue, currency)}
                </div>
                <div className="stat-label">Partner fee</div>
              </div>
              <div className="col-sm-2">
                <div className="stat-value">
                  {formatStatValue(totals[camelCase(clickType)] || 0)}
                </div>
                <div className="stat-label">
                  <ClickTypeFilter {...{ clickType, setClickType }} />
                </div>
              </div>
              <div className="col-sm-2">
                <div className="stat-value">
                  {formatStatValue(totals.count || 0)}
                </div>
                <div className="stat-label">Number of sales</div>
              </div>
            </StatsTotals>
          </StatsPanel>
        )}
      </div>
    </div>
  )
}

const ClickTypeFilter = (props) => {
  const { clickType, setClickType } = props

  const formatClickType = capitalize(clickType.replace('_', ' '))

  return (
    <Dropdown
      listClassName="click-type-options"
      buttonClassName="click-type-filter"
      button={
        <>
          <span>{formatClickType}</span>
          <SVG
            src="/images/icons/arrow-sort-down.svg"
            className="arrow"
            width={10}
            height={10}
          />
        </>
      }
    >
      <li>
        <button onClick={() => setClickType('gross_clicks')}>
          Gross clicks
        </button>
      </li>
      <li>
        <button onClick={() => setClickType('unique_clicks')}>
          Unique clicks
        </button>
      </li>
      <li>
        <button onClick={() => setClickType('rejected_clicks')}>
          Rejected clicks
        </button>
      </li>
      <li>
        <button onClick={() => setClickType('suspicious_clicks')}>
          Suspicious clicks
        </button>
      </li>
    </Dropdown>
  )
}
