import React from 'react'
import styled from 'styled-components'
import Channels from './ShowStatsChannels'
import Products from './ShowStatsProducts'
import Chart from './ShowStatsChart'
import { statTableStyles } from '../shared/elements'

const Container = styled.div`
  ${statTableStyles}

  .panel {
    margin-bottom: 28px;
  }

  table {
    margin-bottom: 0;

    thead {
      tr {
        border-bottom: 1px solid #f4f5f6;
      }
    }

    tbody {
      background: #fefefe;

      .channel {
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          object-fit: contain;
          vertical-align: middle;
        }
      }
    }
  }
`

export default function Stats(props) {
  return (
    <Container>
      <Channels {...props} />
      <Products {...props} />
      <Chart {...props} />
    </Container>
  )
}
