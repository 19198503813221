import React, { useState, useRef } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { Link } from '@reach/router'
import Stats from './ShowStats'
import Contracts from './ShowContracts'
import Billing from './ShowBilling'
import {
  PageTitle,
  PageContent,
  PageLoading,
  DateRangePicker,
  useDateRangePickerState,
} from '../shared/elements'
import { useApiLoad } from '../shared/hooks'
import * as paths from '../../utils/paths'
import { toQuery } from '../../utils/helpers'

const Container = styled.div`
  .tab-controls {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 16px 0 -16px;
    list-style: none;

    a {
      display: inline-block;
      padding: 12px 0;
      color: var(--text-primary);
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
      border-bottom: 3px solid transparent;
      transition: border-bottom 0.15s ease-in-out;

      &.active,
      &:hover {
        border-bottom: 3px solid var(--text-primary);
      }

      &[disabled] {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    li + li > a {
      margin-left: 52px;
    }
  }

  .content {
    .tab {
      display: none;

      &.active {
        display: block;
      }
    }
  }
`

const getContentMinHeight = (ref) => {
  if (ref.current) {
    const rect = ref.current.getBoundingClientRect()
    return window.innerHeight - rect.top
  } else {
    return 0
  }
}

export default function Publisher(props) {
  const { uuid, location } = props

  const dateRangePickerState = useDateRangePickerState()
  const { startDate, defaultStartDate, endDate, defaultEndDate } =
    dateRangePickerState

  const [publisher, setPublisher] = useState(null)
  const [contracts, setContracts] = useState(null)
  const [sourceStats, setSourceStats] = useState(null)
  const [offerStats, setOfferStats] = useState(null)
  const [conversions, setConversions] = useState(null)
  const [totals, setTotals] = useState(null)
  const [invoices, setInvoices] = useState(null)

  useApiLoad(
    `/advertiser/publishers/${uuid}?${toQuery({
      startDate: (startDate || defaultStartDate).toISOString(),
      endDate: (endDate || defaultEndDate).toISOString(),
    })}`,
    publisher === null,
    (response) => {
      if (!response || response.code !== 200) {
        return navigate(paths.errorPath())
      }

      setPublisher(response?.data?.publisher)
      setContracts(response?.data?.contracts)
      setSourceStats(response?.data?.statsBySource)
      setOfferStats(response?.data?.statsByName)
      setConversions(response?.data?.conversions)
      setTotals(response?.data?.conversionTotals)
      setInvoices(response?.data?.invoices)
    }
  )

  const hasContracts = !!contracts?.length

  const reloadData = () => {
    setPublisher(null)
    setSourceStats(null)
    setOfferStats(null)
  }

  const contentRef = useRef()
  const contentMinHeight = getContentMinHeight(contentRef)

  const getTab = (location) => {
    if (!hasContracts) {
      return 'campaigns'
    }

    switch (location.hash) {
      case '#stats':
        return 'stats'
      case '#campaigns':
        return 'campaigns'
      case '#billing':
        return 'billing'
      default:
        return 'campaigns'
    }
  }
  const tabClass = (test) => (tab === test ? 'active' : '')

  const tab = getTab(location)

  const loading =
    sourceStats === null ||
    offerStats === null ||
    conversions === null ||
    contracts === null
  if (loading) {
    return <PageLoading />
  }

  return (
    <Container>
      <PageTitle
        title={publisher.name}
        crumbs={{
          Partners: paths.publishersPath(),
          [publisher.name]: null,
        }}
      >
        <ul className="tab-controls">
          <li>
            <Link to="#campaigns" className={tabClass('campaigns')}>
              Campaigns
            </Link>
          </li>
          <li>
            <Link
              to="#stats"
              className={tabClass('stats')}
              disabled={!hasContracts}
            >
              Statistics
            </Link>
          </li>
          <li>
            <Link
              to="#billing"
              className={tabClass('billing')}
              disabled={!hasContracts}
            >
              Billing
            </Link>
          </li>
        </ul>
      </PageTitle>

      <PageContent>
        {hasContracts && (
          <div className="date-range-picker">
            <DateRangePicker
              state={dateRangePickerState}
              onChange={reloadData}
            />
          </div>
        )}

        <div
          className="content"
          ref={contentRef}
          style={{ minHeight: contentMinHeight }}
        >
          <div className={`tab ${tabClass('stats')}`}>
            <Stats
              publisher={publisher}
              sourceStats={sourceStats}
              offerStats={offerStats}
              conversions={conversions}
              totals={totals}
              startDate={startDate}
              endDate={endDate}
              defaultStartDate={defaultStartDate}
              defaultEndDate={defaultEndDate}
            />
          </div>
          <div className={`tab ${tabClass('campaigns')}`}>
            <Contracts
              publisher={publisher}
              contracts={contracts}
              offerStats={offerStats}
            />
          </div>
          <div className={`tab ${tabClass('billing')}`}>
            <Billing invoices={invoices} />
          </div>
        </div>
      </PageContent>
    </Container>
  )
}
