import React from 'react'
import styled from 'styled-components'
import Invoices from './ShowBillingInvoices'
import { statTableStyles } from '../shared/elements'

const Container = styled.div`
  ${statTableStyles}

  table {
    margin-bottom: 0;

    thead {
      tr {
        border-bottom: 1px solid #f4f5f6;
      }
    }

    tbody {
      background: #fefefe;
    }
  }
`

export default function Billing(props) {
  const { invoices } = props

  if (!invoices || !invoices.length) {
    return (
      <Container>
        <div className="panel text-center">No invoices yet</div>
      </Container>
    )
  }

  return (
    <Container>
      <Invoices {...props} />
    </Container>
  )
}
