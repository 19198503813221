import React, { useState, createContext, useContext } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Link } from '@reach/router'
import SVG from 'react-inlinesvg'
import Publisher from './FormNewPublisher'
import Terms from './FormNewTerms'
import Contract from './FormNewContract'
import ShortlinkFormDialog from './ShortlinksFormDialog'
import ShortlinkShareDialog from './ShortlinksShareDialog'
import { validate } from './FormNewValidation'
import { appContext } from '../shared/contexts'
import { SpinnerCirc } from '../shared/elements'
import {
  defaultContractValues,
  defaultContractDataValues,
} from '../../utils/constants'

const Container = styled.div`
  .form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 42px;

    .back-btn {
      color: var(--text-secondary-light);
      transition: color 0.3s;

      span {
        margin-left: 8px;
        font-size: 18px;
        vertical-align: middle;
      }

      svg {
        vertical-align: middle;
      }

      &:hover {
        color: var(--text-primary);
      }
    }

    .right {
      width: 80px;
    }
  }

  .publisher-label {
    margin-left: -18px;
    margin-bottom: 2px;
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    pointer-events: none;
    transform: scale(0.7);
  }

  .publisher-emails {
    .form-group {
      position: relative;
      margin-bottom: 16px;

      &:hover {
        .btn-remove {
          opacity: 0.5;
        }
      }
    }

    .btn-remove {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      color: var(--text-primary);
      opacity: 0;
      transition: opacity 0.3s;

      svg {
        width: 10px;
      }

      &:hover {
        opacity: 1 !important;
      }
    }
  }

  .collapse-control {
    width: 100%;

    h5 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      span {
        margin-left: 32px;
        font-size: 16px;
        font-weight: normal;
        color: var(--text-secondary-light);
        white-space: nowrap;
      }
    }

    &.open {
      span {
        opacity: 0;
        pointer-events: none;
        user-select: none;
      }
    }
  }

  .commission-types,
  .attribution-types {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: -14px;
    margin-right: -14px;

    .block-radio {
      flex-basis: 33%;
      max-width: 190px;
    }

    .custom-control {
      height: 100%;
    }

    .custom-control-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      h6 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 44px;
        padding: 0 20px;
        margin: 0;
        text-align: center;
        font-size: 13px;
        font-weight: bold;
      }

      svg {
        margin-bottom: 8px;
        opacity: 0.85;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 54px;
        height: 54px;
        border-radius: 27px;
        color: #c7d8eb;
        background: #2b384e;
        opacity: 0.85;
        font-size: 18px;
        font-weight: bold;
      }

      .hint {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 54px;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      svg,
      .icon {
        opacity: 1 !important;
      }
    }
  }

  .form-group.masked,
  .form-group.select,
  .form-group.date,
  .form-group.country {
    margin-bottom: 24px;
  }
`

export const formContext = createContext({})
const FormContextProvider = formContext.Provider

export default function ContractNewForm(props) {
  const {
    cancelPath,
    contract,
    newPublisher,
    publishers,
    conversionRuleSets,
    paymentMethods,
    // showAddPaymentMethod,
    // setShowAddPaymentMethod,
    currentPage,
    setCurrentPage,
  } = props

  const { showMessage } = useContext(appContext)

  const [submitting, setSubmitting] = useState(false)

  const [showShortlinkShare, setShowShortlinkShare] = useState(false)
  const [shareCode, setShareCode] = useState('')

  const [showShortlinkForm, setShowShortlinkForm] = useState(false)
  const [formShortlink, setFormShortlink] = useState(null)
  const [formShortlinkIndex, setFormShortlinkIndex] = useState(null)

  const [publisherShares, setPublisherShares] = useState([''])

  const [shortlinks, setShortlinks] = useState([])
  const [shortlinkShares, setShortlinkShares] = useState([])

  const defaultConversionRuleSet =
    (conversionRuleSets && conversionRuleSets[0]?.uuid) || 'custom'
  const defaultBillingMethod =
    (paymentMethods && paymentMethods[0]?.id) || 'invoice'
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      ...defaultContractValues,
      ...contract,
      data: {
        ...defaultContractDataValues,
        conversionRuleSet: defaultConversionRuleSet,
        billingMethod: defaultBillingMethod,
        ...contract?.data,
        ...contract?.pendingData,
      },
      publisher: {
        name: '',
      },
      conversionRules: [],
    },
  })

  const minPage = newPublisher ? 0 : 1
  const maxPage = 2
  const gotoPage = (nextPage) => {
    if (nextPage >= minPage && nextPage <= maxPage) {
      setCurrentPage(nextPage)
      window.scrollTo(0, 0)
    }
  }
  const goBack = () => {
    gotoPage(currentPage - 1)
  }
  const goForward = async () => {
    const validationResult = await validate(form, publisherShares, currentPage)
    if (validationResult === true) {
      gotoPage(currentPage + 1)
    } else {
      showMessage(validationResult)
    }
  }

  const submit = () => {
    setSubmitting(true)
    const callback = (values) =>
      props.onSubmit({
        ...values,
        publisherShares,
        shortlinks,
        shortlinkShares,
      })
    form
      .handleSubmit(callback)()
      .then(() => {
        setSubmitting(false)
      })
      .catch((error) => {
        showMessage(`Something went wrong: ${error}`)
        setSubmitting(false)
      })
  }

  const fieldsetClassName = (index) => (currentPage === index ? '' : 'd-none')

  return (
    <FormContextProvider
      value={{
        setShowShortlinkShare,
        setShareCode,
        setShowShortlinkForm,
        setFormShortlink,
        setFormShortlinkIndex,
      }}
    >
      <Container>
        <div className="form-header">
          <div className="left">
            <Link to={cancelPath} className="a back-btn">
              <SVG
                src="/images/icons/arrow-point-left.svg"
                width={20}
                height={20}
              />
              <span>Cancel</span>
            </Link>
          </div>
          <div className="middle">
            <SVG src="/images/logo-h.svg" width={36} height={30} />
          </div>
          <div className="right" />
        </div>

        <form>
          {newPublisher && (
            <fieldset className={fieldsetClassName(0)}>
              <Publisher {...{ form, publisherShares, setPublisherShares }} />
            </fieldset>
          )}
          <fieldset className={fieldsetClassName(1)}>
            <Terms {...{ form }} />
          </fieldset>
          <fieldset className={fieldsetClassName(2)}>
            <Contract {...{ form, contract, publishers, conversionRuleSets }} />
          </fieldset>
        </form>

        <div className="form-actions spread mt-3">
          <div className="left">
            <button
              type="button"
              className="btn btn-secondary px-5"
              hidden={submitting || currentPage <= minPage}
              style={{ opacity: currentPage <= minPage ? 0 : 1 }}
              onClick={goBack}
            >
              Back
            </button>
          </div>
          <div className="middle">
            <SpinnerCirc show={submitting} />
          </div>
          <div className="right">
            <button
              type="button"
              className="btn btn-primary px-5"
              hidden={submitting || currentPage >= maxPage}
              onClick={goForward}
            >
              Continue
            </button>
            <button
              type="button"
              className="btn btn-primary px-5"
              hidden={submitting || currentPage < maxPage}
              onClick={submit}
            >
              Save & finish
            </button>
          </div>
        </div>
      </Container>

      <ShortlinkFormDialog
        show={showShortlinkForm}
        toggle={setShowShortlinkForm}
        form={form}
        contract={contract}
        shortlink={formShortlink}
        shortlinkIndex={formShortlinkIndex}
        onShare={(code) => {
          setShareCode(code)
          setShowShortlinkShare(true)
        }}
        addShortlink={(values) => {
          const newShortlinks = [...shortlinks, values]
          setShortlinks(newShortlinks)
        }}
        updateShortlink={(index, values) => {
          const newShortlinks = [...shortlinks]
          newShortlinks[index] = { ...newShortlinks[index], ...values }
          setShortlinks(newShortlinks)
        }}
        removeShortlink={(index) => {
          const newShortlinks = [...shortlinks]
          newShortlinks.splice(index, 1)
          setShortlinks(newShortlinks)
        }}
      />

      <ShortlinkShareDialog
        show={showShortlinkShare}
        toggle={setShowShortlinkShare}
        form={form}
        code={shareCode}
        addShare={(code, emails) => {
          const newShortlinkShares = [...shortlinkShares, { code, emails }]
          setShortlinkShares(newShortlinkShares)
        }}
      />
    </FormContextProvider>
  )
}
