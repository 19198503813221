export const validate = (form, page) => {
  const values = form.getValues()
  const fields = []

  let fieldset = ''
  if (page === 0) fieldset = 'publisher'
  if (page === 1) fieldset = 'terms'
  if (page === 2) fieldset = 'billing'
  if (page === 3) fieldset = 'shortlinks'

  if (fieldset === 'publisher') {
    fields.push('publisher.name', 'data.managerName', 'data.managerEmail')
  }

  if (fieldset === 'terms') {
    fields.push(
      'data.startsAt',
      'data.endsAt',
      'data.commissionModel',
      'data.currency'
    )

    if (values.data.commissionModel === 'CPS') {
      fields.push('data.commissionPercentage')
    } else {
      fields.push('data.commissionPrice')
    }

    fields.push(
      'data.trackingPeriod',
      'data.trackingPeriodType',
      'data.transactionDeclinePeriod',
      'data.transactionDeclinePeriodType'
    )
  }

  if (fieldset === 'billing') {
    fields.push('data.billingType', 'data.billingMethod')
  }

  if (fieldset === 'shortlinks') {
    fields.push('code')
  }

  if (!fieldset) {
    return true
  }

  return form.trigger(fields)
}
