import React from 'react'
import { useForm } from 'react-hook-form'
import { TextField, Checkbox, DateField } from '../shared/fields'
import { validates } from '../../utils/constants'

export default function Form(props) {
  const { initialValue } = props

  const isNew = !initialValue

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      targetUrl: '',
      startsAt: undefined,
      endsAt: undefined,
      isVisible: true,
      ...initialValue,
    },
  })

  const submit = () => {
    form.handleSubmit(props.onSubmit)()
  }

  return (
    <form>
      <h5>Link details</h5>

      <div className="row">
        <div className="col-md-12">
          <TextField
            form={form}
            validation={validates.required}
            name="name"
            label="Link name"
          />
        </div>

        <div className="col-md-12">
          <TextField
            form={form}
            validation={validates.required}
            name="targetUrl"
            label="Target URL"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <DateField
            form={form}
            name="startsAt"
            label="Active since (optional)"
            clearable={true}
          />
        </div>
        <div className="col-md-6">
          <DateField
            form={form}
            name="endsAt"
            label="Active until (optional)"
            clearable={true}
          />
        </div>
      </div>

      <Checkbox form={form} name="isVisible" label="Visible to publisher" />

      <hr />

      <div className="form-actions">
        <button type="button" className="btn btn-primary" onClick={submit}>
          {isNew ? 'Create smart link' : 'Update smart link'}
        </button>
      </div>
    </form>
  )
}
