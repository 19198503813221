import React from 'react'
import {
  formatStatValue,
  formatStatPercentage,
} from '../../utils/helpers/stats'
import { defaultLogoUrl } from '../../utils/constants'

export default function StatsChannels(props) {
  const { publisher } = props
  const stats = props.sourceStats

  const statsPresent = stats && stats.length

  return (
    <div className="panel">
      <div className="table-container">
        <div className="table-header">Channels</div>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <td>Gross clicks</td>
              <td>Unique clicks</td>
              <td>Rejected clicks</td>
              <td>Suspicious clicks</td>
              <td>Conversions</td>
              <td>CR</td>
              <td>ABS</td>
              <td>Partner fee</td>
              <td>Total sales</td>
              <td>NET profit</td>
            </tr>
          </thead>
          <tbody>
            {statsPresent ? (
              stats.map((stat, i) => (
                <ChannelStat key={i} publisher={publisher} stat={stat} />
              ))
            ) : (
              <tr>
                <th>&nbsp;</th>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const ChannelStat = (props) => {
  const { publisher, stat } = props

  const grossClicks = parseInt(stat.gross_clicks)
  const uniqueClicks = parseInt(stat.unique_clicks)
  const rejectedClicks = parseInt(stat.rejected_clicks)
  const suspiciousClicks = parseInt(stat.suspicious_clicks)

  const conversionCount = parseInt(stat.conversions)
  const cr = (conversionCount / (grossClicks || 1)) * 100
  const payout = parseFloat(stat.payout)
  const revenue = parseFloat(stat.revenue)
  const saleAmount = parseFloat(stat.sale_amount) || 0
  const avgSaleAmount = saleAmount / (conversionCount || 1)
  const profit = saleAmount - payout

  return (
    <tr className="channel">
      <th>
        <img src={publisher.logoUrl || defaultLogoUrl} alt="logo" />
        <span>{stat.source || 'Unknown'}</span>
      </th>
      <td>{formatStatValue(grossClicks)}</td>
      <td>{formatStatValue(uniqueClicks)}</td>
      <td>{formatStatValue(rejectedClicks)}</td>
      <td>{formatStatValue(suspiciousClicks)}</td>
      <td>{formatStatValue(conversionCount)}</td>
      <td>{formatStatPercentage(cr)}</td>
      <td>{formatStatValue(avgSaleAmount, true)}</td>
      <td>{formatStatValue(revenue, true)}</td>
      <td>{formatStatValue(saleAmount, true)}</td>
      <td>{formatStatValue(profit, true)}</td>
    </tr>
  )
}
