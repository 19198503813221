import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { directFilterStyles } from '../shared/elements'

const FilterQuery = styled.div`
  ${directFilterStyles}
`

export default function Filter(props) {
  const { filterQuery, setFilterQuery } = props

  return (
    <FilterQuery>
      <button type="button" className="submit">
        <SVG src="/images/icons/search.svg" />
      </button>
      <input
        value={filterQuery}
        onChange={(event) => {
          setFilterQuery(event.target.value)
        }}
        onKeyDown={(event) => {
          switch (event.key) {
            case 'Enter':
              event.target.blur()
              break
            case 'Escape':
              setFilterQuery('')
              break
            default:
          }
        }}
        placeholder="Search"
      />
      {filterQuery && (
        <button
          type="button"
          className="cancel"
          onClick={() => {
            setFilterQuery('')
          }}
        >
          <SVG src="/images/icons/times.svg" />
        </button>
      )}
    </FilterQuery>
  )
}
