import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import MaskedInput from 'react-text-mask'
import SVG from 'react-inlinesvg'
import { get } from 'lodash'
import {
  SortColumn,
  directTableStyles,
  directPaginationStyles,
} from '../shared/elements'
import { formatMoney } from '../../utils/helpers'
import { formatStatValue } from '../../utils/helpers/stats'

const Container = styled.div`
  .table-container {
    ${directTableStyles}

    .no-products {
      padding: 24px 0;
      text-align: center;

      .h1 {
        margin-bottom: 8px;
        font-size: 36px;
        font-weight: bold;
      }
    }
  }

  .pagination-container {
    ${directPaginationStyles}
  }
`

const perPage = 5

export default function Products(props) {
  const { contracts, products } = props

  const [page, setPage] = useState(1)
  const [order, setOrder] = useState('quantity')
  const [sort, setSort] = useState('desc')

  const pages = Math.ceil(products.length / perPage)
  const shownProducts = useMemo(
    () =>
      (products || [])
        .map((product) => {
          const price = product.priceCents / 100
          const payout = product.payoutCents / 100
          const saleAmount = product.saleAmountCents / 100
          const profit = (product.saleAmountCents - product.payoutCents) / 100
          const grossMargin = saleAmount ? (profit / saleAmount) * 100 : 0

          return {
            ...product,
            price,
            payout,
            saleAmount,
            profit,
            grossMargin,
          }
        })
        .sort((productA, productB) => {
          const a = get(productA, order)
          const b = get(productB, order)
          const asc = sort === 'asc'

          if (a < b) {
            return asc ? -1 : 1
          } else if (a > b) {
            return asc ? 1 : -1
          } else {
            return 0
          }
        })
        .slice((page - 1) * perPage, page * perPage),
    [products, page, order, sort]
  )

  if (!products?.length) {
    return (
      <Container>
        <div className="table-container">
          <div className="no-products">
            <div className="h1">No data</div>
            <div>
              Make sure you have added product data to conversions to see
              reports for individual products
            </div>
          </div>
        </div>
      </Container>
    )
  }

  const sortProps = {
    onChange: () => setPage(1),
    order,
    setOrder,
    sort,
    setSort,
  }

  return (
    <Container>
      <div className="table-container">
        <table className="table table-borderless">
          <thead>
            <tr>
              <td className="spacer" />
              <SortColumn
                element="td"
                label="#"
                className="text-left"
                field="itemId"
                defaultSort="asc"
                {...sortProps}
              />
              <SortColumn
                element="td"
                label="Product name"
                className="text-left"
                field="itemName"
                defaultSort="asc"
                {...sortProps}
              />
              <SortColumn
                element="td"
                label="Amount sold"
                field="quantity"
                defaultSort="desc"
                {...sortProps}
              />
              <SortColumn
                element="td"
                label="Cost"
                className="text-right hidden"
                field="price"
                defaultSort="desc"
                {...sortProps}
              />
              <SortColumn
                element="td"
                label="Total sales"
                className="text-right"
                field="saleAmount"
                defaultSort="desc"
                {...sortProps}
              />
              <SortColumn
                element="td"
                label="Partner fee"
                className="text-right"
                field="payout"
                defaultSort="desc"
                {...sortProps}
              />
              <SortColumn
                element="td"
                label="Profit"
                className="text-right"
                field="profit"
                defaultSort="desc"
                {...sortProps}
              />
              <td className="text-right">Ad spend</td>
              <SortColumn
                element="td"
                label="Gross margin"
                field="grossMargin"
                defaultSort="desc"
                {...sortProps}
              />
              <td className="spacer" />
            </tr>
          </thead>
          <tbody>
            {shownProducts.map((product) => (
              <Product
                key={product.itemHash}
                contracts={contracts}
                product={product}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <Pagination page={page} pages={pages} setPage={setPage} />
      </div>
    </Container>
  )
}

const Product = (props) => {
  const { contracts, product } = props

  const contract = contracts.find(
    (contract) => contract.tuneOfferId === product.tuneOfferId
  )
  const currency = contract?.data.currency

  const price = product.priceCents / 100
  const payout = product.payoutCents / 100
  const saleAmount = product.saleAmountCents / 100
  const profit = (product.saleAmountCents - product.payoutCents) / 100
  const grossMargin = saleAmount ? (profit / saleAmount) * 100 : 0

  return (
    <tr>
      <td className="spacer" />
      <td className="text-secondary text-left">{product.itemId}</td>
      <td className="nowrap text-left">{product.itemName}</td>
      <td>{formatStatValue(product.quantity)}</td>
      <td className="text-right hidden">{formatMoney(price, currency)}</td>
      <td className="text-right">{formatMoney(saleAmount, currency)}</td>
      <td className="text-right">{formatMoney(payout, currency)}</td>
      <td className="text-right">{formatMoney(profit, currency)}</td>
      <td className="text-right">No data</td>
      <td>{Math.floor(grossMargin)}%</td>
      <td className="spacer" />
    </tr>
  )
}

const Pagination = (props) => {
  const { page, pages, setPage } = props

  const [showPage, setShowPage] = useState(page)
  const gotoPage = (newPage) => {
    if (newPage > 0 && newPage <= pages) {
      setPage(newPage)
    } else {
      setShowPage(page)
    }
  }

  useEffect(() => {
    setShowPage(page)
  }, [page])

  if (pages <= 1) {
    return null
  }

  return (
    <>
      <button className="prev" onClick={() => gotoPage(Number(page) - 1)}>
        <SVG src="/images/icons/arrow-left.svg" />
      </button>
      <div className="page text-primary">
        Page
        <MaskedInput
          className={Number(showPage) > 99 ? 'large' : ''}
          mask={Array(3).fill(/\d/)}
          guide={false}
          value={showPage}
          onChange={(event) => {
            setShowPage(event.target.value)
          }}
          onBlur={() => {
            gotoPage(Number(showPage))
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              gotoPage(Number(showPage))
            }
          }}
        />
        of {pages}
      </div>
      <button className="next" onClick={() => gotoPage(Number(page) + 1)}>
        <SVG src="/images/icons/arrow-left.svg" />
      </button>
    </>
  )
}
