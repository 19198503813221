import React from 'react'
import SVG from 'react-inlinesvg'
import { TextField, TextFormGroup } from '../shared/fields'
import { validates } from '../../utils/constants'

export default function ContractFormNewPublisher(props) {
  const { form, publisherShares, setPublisherShares } = props

  const changeEmail = (index) => (event) => {
    const newPublisherShares = [...publisherShares]
    newPublisherShares[index] = event.target.value
    setPublisherShares(newPublisherShares)
  }
  const addEmail = () => {
    const newPublisherShares = [...publisherShares]
    newPublisherShares.push('')
    setPublisherShares(newPublisherShares)
  }
  const removeEmail = (index) => () => {
    const newPublisherShares = [...publisherShares]
    newPublisherShares.splice(index, 1)
    setPublisherShares(newPublisherShares)
  }

  return (
    <div className="panel px-35 py-4">
      <h5 className="mt-0">Invite a new partner</h5>

      <p>
        Each invited partner will receive an email with an invitation to work
        with your company through Heylink Connect.
      </p>

      <div className="row mb-4">
        <div className="col-md-8">
          <label className="publisher-label" htmlFor="publisher.name">
            Partner company
          </label>
          <TextField
            form={form}
            validation={validates.required}
            name="publisher.name"
            wrapperClassName="mb-0"
          />
        </div>
      </div>

      <div className="publisher-emails">
        {publisherShares.map((email, index) => (
          <div key={index} className="row">
            <div className="col-md-8">
              <div className="form-group">
                {index === 0 && (
                  <label
                    className="publisher-label pl-1"
                    htmlFor="publisherShare"
                  >
                    Partner e-mail
                  </label>
                )}
                <TextFormGroup className="mb-0">
                  <input
                    type="email"
                    name="publisherShare"
                    className="form-control"
                    placeholder="example@domain.com"
                    value={email}
                    onChange={changeEmail(index)}
                  />
                  {publisherShares.length > 1 && (
                    <button
                      type="button"
                      className="btn btn-remove"
                      onClick={removeEmail(index)}
                    >
                      <SVG src="/images/icons/times-fat.svg" />
                    </button>
                  )}
                </TextFormGroup>
              </div>
            </div>
          </div>
        ))}

        <div className="row">
          <div className="col-md-8">
            <div className="form-actions mt-0">
              <button
                type="button"
                className="a text-primary"
                onClick={addEmail}
              >
                + Add email
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
