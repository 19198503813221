import React from 'react'
import moment from 'moment'
import { getCurrentUser } from '../../services/auth'
import {
  capitalize,
  formatInvoiceName,
  formatInvoiceBalance,
} from '../../utils/helpers'
import { invoiceUrl } from '../../utils/paths'

const formatDate = (date) => moment(date).format('MMM D, YYYY')

export default function BillingInvoices(props) {
  const { invoices } = props

  const currentUser = getCurrentUser()

  return (
    <div className="panel">
      <div className="table-container">
        <div className="table-header">Invoices</div>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <td>Amount</td>
              <td>Method</td>
              <td>Status</td>
              <td>Date</td>
              <td>Part of invoice</td>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice, i) => (
              <tr key={i}>
                <th>{formatInvoiceName(invoice)}</th>
                <td>{formatInvoiceBalance(invoice)}</td>
                <td>CreditCard</td>
                <td>{capitalize(invoice.status)}</td>
                <td>{formatDate(invoice.createdAt)}</td>
                <td>
                  {currentUser.advertiserUuid && (
                    <a
                      href={invoiceUrl(
                        currentUser.advertiserUuid,
                        invoice.uuid
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      INV#{invoice.hash.substr(0, 8).toUpperCase()}
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
