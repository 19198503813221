import React from 'react'
import SVG from 'react-inlinesvg'

export default function ContractFormPageList(props) {
  const { pages, currentPage } = props

  const pageClass = (index) =>
    index === currentPage ? 'form-page active' : 'form-page'

  return (
    <div className="form-page-list">
      {pages.map((label, index) => (
        <div key={label} className={pageClass(index)}>
          <span title={label}>{label}</span>
          <SVG src="/images/icons/arrow-left.svg" className="separator" />
        </div>
      ))}
    </div>
  )
}
