import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { directFilterStyles } from '../shared/elements'
import { filterStatuses } from '../../utils/constants'

const FilterQuery = styled.div`
  ${directFilterStyles}
`

const FilterStatus = styled.div`
  margin-left: 10px;

  select {
    width: 180px;
    height: 46px;
    padding: 14px 14px 10px;
    border: none;
    border-radius: 4px;
    background: var(--body-background);
  }
`

export default function ContractFilter(props) {
  const { filterQuery, setFilterQuery, filterStatus, setFilterStatus } = props

  return (
    <>
      <FilterQuery>
        <button type="button" className="submit" onClick={() => {}}>
          <SVG src="/images/icons/search.svg" />
        </button>
        <input
          value={filterQuery}
          onChange={(event) => {
            setFilterQuery(event.target.value)
          }}
          onKeyDown={(event) => {
            switch (event.key) {
              case 'Enter':
                event.target.blur()
                break
              case 'Escape':
                setFilterQuery('')
                break
              default:
            }
          }}
          placeholder="Search"
        />
        {filterQuery && (
          <button
            type="button"
            className="cancel"
            onClick={() => {
              setFilterQuery('')
            }}
          >
            <SVG src="/images/icons/times.svg" />
          </button>
        )}
      </FilterQuery>
      {false && (
        <FilterStatus>
          <select
            className="text-primary"
            value={filterStatus}
            onChange={(event) => {
              setFilterStatus(event.target.value)
            }}
            onBlur={(event) => {
              setFilterStatus(event.target.value)
            }}
          >
            <option value="">Status</option>
            {filterStatuses.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </FilterStatus>
      )}
    </>
  )
}
