import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { appContext } from '../shared/contexts'
import { Dialog } from '../shared/elements'
import { postRequest } from '../../services/api'
import { emailRegex } from '../../utils/constants'

const Form = styled.form`
  .form-group {
    position: relative;
    margin-bottom: 16px;

    &:hover {
      .btn-remove {
        opacity: 0.5;
      }
    }
  }

  .form-control {
    height: 44px;
    padding: 12px 20px;
    padding-right: 40px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    color: var(--text-primary) !important;
    background: var(--input-background);
  }

  .btn-remove {
    position: absolute;
    height: 44px;
    top: 0;
    right: 0;
    color: var(--text-primary);
    opacity: 0;
    transition: opacity 0.3s;

    svg {
      width: 10px;
    }

    &:hover {
      opacity: 1 !important;
    }
  }

  .form-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0 8px;
  }
`

export default function ShareDialog(props) {
  const { show, toggle, code } = props

  const { showMessage } = useContext(appContext)

  const [emails, setEmails] = useState([''])
  const changeEmail = (index) => (event) => {
    const newEmails = [...emails]
    newEmails[index] = event.target.value
    setEmails(newEmails)
  }
  const addEmail = () => {
    const newEmails = [...emails]
    newEmails.push('')
    setEmails(newEmails)
  }
  const removeEmail = (index) => () => {
    const newEmails = [...emails]
    newEmails.splice(index, 1)
    setEmails(newEmails)
  }

  useEffect(() => {
    if (show === false) {
      setEmails([''])
    }
  }, [show])

  const submit = async (event) => {
    event.preventDefault()
    const filteredEmails = emails.filter((email) => email.match(emailRegex))

    if (!filteredEmails.length) {
      const firstDialogInput = document.querySelector(
        '[class^="Dialog"] input[type="email"]'
      )
      firstDialogInput && firstDialogInput.focus()
      return
    }

    try {
      await postRequest('/advertiser/shortlinks/share', {
        code: code,
        emails: filteredEmails,
      })
      showMessage('Emails will be sent shortly')
      toggle(false)
    } catch (error) {
      showMessage(`Something went wrong: ${error}`)
    }
  }

  return (
    <Dialog show={show} toggle={toggle}>
      <Form onSubmit={submit}>
        <h4 className="mt--25 mb-30">Share a smart link</h4>
        <p>Add emails to share your smart link with:</p>
        {emails.map((email, index) => (
          <div key={index} className="form-group">
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={changeEmail(index)}
            />
            {emails.length > 1 && (
              <button
                type="button"
                className="btn btn-remove"
                onClick={removeEmail(index)}
              >
                <SVG src="/images/icons/times-fat.svg" />
              </button>
            )}
          </div>
        ))}
        <div className="form-actions">
          <button type="button" className="btn btn-primary" onClick={addEmail}>
            + Add email
          </button>
          <button className="btn btn-primary">Send</button>
        </div>
      </Form>
    </Dialog>
  )
}
