import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'
import * as paths from '../../utils/paths'
import { getCurrentCurrency } from '../../utils/currencies'
import { convertMoney } from '../../utils/helpers'
import { sumInt, sumFloat, formatStatValue } from '../../utils/helpers/stats'

const Container = styled.div.attrs((props) => ({
  className: 'panel',
}))`
  padding-top: 12px;
  overflow: hidden;

  .header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      margin-right: 16px;
      border-radius: 10px;
      object-fit: contain;
    }

    h5 {
      position: relative;
      top: 6px;
    }

    .partner-type {
      font-size: 12px;
      letter-spacing: -0.2px;
    }

    .btn-header {
      color: inherit;
    }

    .btn-edit {
      position: absolute;
      top: -18px;
      right: -8px;
      padding: 8px;
      color: var(--text-secondary);
      cursor: pointer;

      &:hover {
        color: var(--text-secondary-hover);
      }
    }

    .btn-details {
      position: absolute;
      bottom: -10px;
      right: 0px;
      color: var(--text-link);
      font-family: var(--body-font);
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        color: var(--text-link-hover);
      }
    }
  }

  .content-container {
    position: relative;
  }

  .content {
    .label {
      font-size: 12px;
      white-space: nowrap;
    }

    .value {
      font-size: 18px;
      font-weight: 500;
      white-space: nowrap;

      &.text-green {
        color: #009900;
      }

      &.text-red {
        color: #cc0000;
      }

      &.text-long {
        font-size: 15px;
      }

      > span:first-of-type {
        margin-right: -2px;
        font-size: 14px;
        letter-spacing: -0.2px;
      }
    }
  }

  .content-overlay {
    position: absolute;
    inset: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(1.5px);

    .btn {
      white-space: nowrap;
    }
  }
`

const formatMoneyValue = (value, currency) => {
  value = Number(value) || 0
  const [convertedValue, convertedCurrency] = convertMoney(value, currency)
  if (convertedValue > 1000000) {
    return `${(convertedValue / 1000000).toFixed(1)}M`
  } else if (convertedValue > 1000) {
    return `${(convertedValue / 1000).toFixed(1)}K`
  } else {
    return `${convertedValue.toFixed(2)}`
  }
}

const formatTitleMoney = (value, currency) => {
  const [convertedValue, convertedCurrency] = convertMoney(value, currency)
  return `${convertedCurrency || currency} ${Number(
    convertedValue || value
  ).toFixed(2)}`
}

const formatTrend = (value) => {
  if (value > 0) {
    return `+${Math.floor(value)}%`
  } else if (value < 0) {
    return `${Math.floor(value)}%`
  } else {
    return '0%'
  }
}

const trendValueClassName = (value) => {
  if (value > 0) {
    return 'text-green'
  } else if (value < 0) {
    return 'text-red'
  } else {
    return ''
  }
}

export default function Partner(props) {
  const { publisher, stats, conversions } = props

  const grossClicks = sumInt(stats, 'gross_clicks')
  const conversionCount = sumInt(stats, 'conversions')
  const payout = sumFloat(conversions, 'payout')
  const saleAmount = sumFloat(conversions, 'sale_amount')
  const profit = saleAmount - payout
  const trend = sumFloat(conversions, 'trend') / (conversions.length || 1)
  const currency = conversions[0]?.currency || 'DKK'

  return (
    <Container>
      <div className="header">
        <div>
          <Link to={paths.publisherPath(publisher.uuid)} className="btn-header">
            <h5>{publisher.name}</h5>
          </Link>
          <div className="partner-type">Direct partnership</div>
        </div>
        {/*
          <button className="a btn-edit">
            <SVG src="/images/icons/cog.svg" />
          </button>
        */}
        <Link to={paths.publisherPath(publisher.uuid)} className="btn-details">
          Details
        </Link>
      </div>

      <hr />

      <div className="content-container">
        <div className="content row mb-3">
          <div className="col-sm-4">
            <div className="label">Visits</div>
            <div className="value">{formatStatValue(grossClicks)}</div>
          </div>
          <div className="col-sm-4">
            <div className="label">Total sales</div>
            <div
              className="value"
              title={formatTitleMoney(saleAmount, currency)}
            >
              <span>{getCurrentCurrency()}</span>{' '}
              {formatMoneyValue(saleAmount, currency)}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="label">Total profit</div>
            <div className="value" title={formatTitleMoney(profit, currency)}>
              <span>{getCurrentCurrency()}</span>{' '}
              {formatMoneyValue(profit, currency)}
            </div>
          </div>
        </div>

        <div className="content row">
          <div className="col-sm-4">
            <div className="label">Conversions</div>
            <div className="value">{formatStatValue(conversionCount)}</div>
          </div>
          <div className="col-sm-4">
            <div className="label">Partner fee</div>
            <div className="value" title={formatTitleMoney(payout, currency)}>
              <span>{getCurrentCurrency()}</span>{' '}
              {formatMoneyValue(payout, currency)}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="label">30 day trend</div>
            <div className={`value ${trendValueClassName(trend)}`}>
              {formatTrend(trend)}
            </div>
          </div>
        </div>

        {publisher.hasPendingContracts && (
          <div className="content-overlay">
            <Link
              to={paths.publisherPath(publisher.uuid) + '#campaigns'}
              className="btn btn-secondary"
            >
              Pending connection
            </Link>
          </div>
        )}
      </div>
    </Container>
  )
}
