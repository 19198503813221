import React from 'react'
import { useWatch } from 'react-hook-form'
import styled from 'styled-components'
import { Radio } from '../shared/fields'
import { getValue } from '../../utils/helpers'

const Container = styled.div`
  .form-group.boolean {
    padding: 20px 32px;
    border: 1px solid var(--input-background);
    border-radius: 4px;
    background: var(--input-background);
    transition: border-color 0.3s;

    &.active {
      border-color: var(--text-primary);
    }

    .hint {
      margin-bottom: 12px;
    }

    .hint-fee {
      margin-right: 16px;
      margin-bottom: 0;
      white-space: nowrap;

      > span {
        display: inline-block;
        margin-left: 6px;
        vertical-align: top;
        white-space: normal;
      }
    }
  }

  button.a {
    color: var(--text-link);
  }
`

export default function ContractBilling(props) {
  const { form, paymentMethods, setShowAddPaymentMethod } = props

  useWatch({
    control: form.control,
    name: ['data.billingType', 'data.billingMethod'],
  })

  const currentBillingType = getValue(form.getValues, 'data.billingType')
  const billingTypeClassName = (type) => {
    return type === currentBillingType ? 'active' : ''
  }

  const currentBillingMethod = getValue(form.getValues, 'data.billingMethod')
  const billingMethodClassName = (type) => {
    return type === currentBillingMethod ? 'active' : ''
  }

  return (
    <Container>
      <div className="mb-4">
        <h5 className="mb-0">Billing type</h5>
        <div className="hint">
          Choose how you want the Partner fee to be charged and paid.
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <Radio
            form={form}
            name="data.billingType"
            value="self"
            wrapperClassName={`mb-3 ${billingTypeClassName('self')}`}
            Label={(props) => (
              <>
                <strong>Self billing</strong>
                <div className="hint">
                  Choose self billing if you want to pay fee directly to the
                  Partner.
                </div>
                <div className="hint hint-fee">
                  <strong>Fee:</strong>
                  <span>
                    1% of the tracked sales value is billed to you monthly.
                  </span>
                </div>
              </>
            )}
          />

          <Radio
            form={form}
            name="data.billingType"
            value="heylink"
            wrapperClassName={billingTypeClassName('heylink')}
            Label={(props) => (
              <>
                <strong>Automatic invoicing and payout</strong>
                <div className="hint">
                  Automatic invoicing and payout based on the terms set in the
                  campaign. Heylink will charge you the fee to be paid to the
                  Partner.
                </div>
                <div className="hint hint-fee">
                  <strong>Fee:</strong>
                  <span>
                    1% of the tracked sales value is billed to you monthly.
                    <br />
                    0.5 - 2% of the fee amount depending on payment method and
                    currency.
                  </span>
                </div>
              </>
            )}
          />
        </div>
      </div>

      <div className="mb-4">
        <h5 className="mt-1 mb-0">Billing method</h5>
        <div className="hint">
          Choose how you want the Partner fee to be charged and paid.
        </div>
      </div>

      {paymentMethods?.map((paymentMethod) => (
        <div key={paymentMethod.id} className="row">
          <div className="col-md-6 col-lg-4">
            <Radio
              form={form}
              name="data.billingMethod"
              value={paymentMethod.id}
              wrapperClassName={`mb-3 ${billingMethodClassName(
                paymentMethod.ie
              )}`}
              Label={(props) => (
                <>
                  <strong>
                    Saved {paymentMethod.cardBrand} {paymentMethod.cardFunding}{' '}
                    card
                  </strong>
                  <div className="hint mb-0">
                    **** **** **** {paymentMethod.cardLast4}
                  </div>
                </>
              )}
            />
          </div>
        </div>
      ))}

      <div className="row">
        <div className="col-md-6 col-lg-4">
          <Radio
            form={form}
            name="data.billingMethod"
            value="invoice"
            wrapperClassName={`mb-3 ${billingMethodClassName('invoice')}`}
            Label={(props) => (
              <>
                <strong>Invoice</strong>
                <div className="hint mb-0">We will send you an invoice</div>
              </>
            )}
          />
        </div>
      </div>

      <button
        className="a"
        type="button"
        onClick={() => setShowAddPaymentMethod(true)}
        hidden
      >
        + Add new payment method
      </button>
    </Container>
  )
}
