import React from 'react'
import { TextField } from '../shared/fields'
import { validates } from '../../utils/constants'

export default function ContractPublisher(props) {
  const { form } = props

  return (
    <>
      <h5>Partner details</h5>

      <div className="row">
        <div className="col-md-8">
          <TextField
            form={form}
            validation={validates.required}
            name="publisher.name"
            label="Company name"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <TextField
            form={form}
            validation={validates.required}
            name="data.managerName"
            label="Contact name"
          />
        </div>

        <div className="col-md-6">
          <TextField
            form={form}
            validation={{ ...validates.required, ...validates.email }}
            name="data.managerEmail"
            label="Contact e-mail"
          />
        </div>
      </div>
    </>
  )
}
