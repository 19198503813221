import React, { useContext } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { formContext } from './Form'
import { appContext } from '../shared/contexts'
import { ContractShortlinkCode, directTableStyles } from '../shared/elements'
import { TextField } from '../shared/fields'
import { copyToClipboard } from '../../utils/helpers'
import { shortlinkHost } from '../../utils/constants'

const Container = styled.div`
  .table-container {
    ${directTableStyles}

    tr td {
      text-align: left;
    }

    .shortlink-name {
      button {
        font-family: inherit;
        font-weight: bold;
        color: inherit;

        &:hover {
          color: var(--text-primary-hover);
        }
      }

      svg {
        width: 12px;
        height: 12px;
        margin-left: 8px;
        color: var(--text-light);
        opacity: 0;
        transition: opacity 0.3s;
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }
    }

    .shortlink-url {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      span {
        font-family: inherit;
        color: inherit;

        &:hover {
          color: var(--text-primary-hover);
        }
      }

      button {
        padding: 0 8px;
        margin-left: 8px;
        border: none;
        color: var(--text-primary);
        background: transparent;
        outline: none;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: var(--text-primary-hover);
        }

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    .add {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      padding: 14px 20px 12px;
      margin: 24px 24px 0;
      font-weight: bold;

      svg {
        margin-top: -2px;
        margin-left: 10px;
      }
    }
  }
`

export default function ContractShortlinks(props) {
  const { form, contract, shortlinks } = props

  const {
    setShowShortlinkShare,
    setShareCode,
    setShowShortlinkForm,
    setFormShortlink,
    setFormShortlinkIndex,
  } = useContext(formContext)

  const { showMessage } = useContext(appContext)

  const share = () => {
    setShareCode(contract.shortlinkCode)
    setShowShortlinkShare(true)
  }

  return (
    <Container>
      <div className="mb-4">
        <h5 className="mb-0">Campaign link</h5>
        <div className="hint">
          This is the default link of the campaign. Send this link to your
          Partners.
        </div>
      </div>

      <TextField
        form={form}
        type="hidden"
        name="shortlinkCode"
        wrapperClassName="d-none"
      />
      <ContractShortlinkCode contract={contract} share={share} />

      <div className="mt-5">
        <h5 className="mb-0">Add more smart links</h5>
        <div className="hint">
          If you want to measure traffic on different pages or positions, create
          smart links for each.
        </div>
      </div>

      <div className="table-container">
        {shortlinks?.length > 0 && (
          <table className="table table-borderless">
            <thead>
              <tr>
                <td className="spacer" />
                <td className="text-left">Name</td>
                <td>Smart link</td>
                <td>Destination</td>
                <td className="spacer" />
              </tr>
            </thead>
            <tbody>
              {shortlinks.map((shortlink, index) => (
                <tr key={shortlink.code}>
                  <td className="spacer" />
                  <td className="shortlink-name">
                    <button
                      type="button"
                      className="a"
                      onClick={() => {
                        setFormShortlink(shortlink)
                        setFormShortlinkIndex(index)
                        setShowShortlinkForm(true)
                      }}
                    >
                      {shortlink.name}
                      <SVG src="/images/icons/pencil-filled.svg" />
                    </button>
                  </td>
                  <td
                    className={`shortlink-url shortlink-url-${shortlink.code}`}
                  >
                    <span>
                      {shortlinkHost}/{shortlink.code}
                    </span>
                    <button
                      onClick={copyToClipboard(
                        `.shortlink-url-${shortlink.code} span`,
                        () => showMessage('Link copied to clipboard')
                      )}
                    >
                      <SVG src="/images/icons/clipboard.svg" />
                    </button>
                  </td>
                  <td>
                    {shortlink.targetUrl.trim() || `https://${contract.url}`}
                  </td>
                  <td className="spacer" />
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <button
          type="button"
          className="add btn btn-primary"
          onClick={() => {
            setFormShortlink(null)
            setFormShortlinkIndex(-1)
            setShowShortlinkForm(true)
          }}
        >
          Add smart link <SVG src="/images/icons/plus.svg" />
        </button>
      </div>
    </Container>
  )
}
