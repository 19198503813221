import React, { useState, useContext } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Form from './Form'
import PageList from './FormPageList'
import { appContext } from '../shared/contexts'
import {
  PageTitle,
  PageContent,
  PageLoading,
  AddPaymentMethodDialog,
  directFormStyles,
} from '../shared/elements'
import { useApiLoad } from '../shared/hooks'
import { getRequest, putRequest } from '../../services/api'
import * as paths from '../../utils/paths'

const Container = styled.div`
  ${directFormStyles}
`

export default function ContractEdit(props) {
  const { contractUuid } = props

  const { showMessage } = useContext(appContext)

  const [contract, setContract] = useState(null)
  const [publisher, setPublisher] = useState(null)
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  useApiLoad(
    `/advertiser/contracts/${contractUuid}/form`,
    contract === null,
    (response) => {
      setContract(response?.data?.contract)
      setPublisher(response?.data?.publisher)
      setPaymentMethods(response?.data?.paymentMethods)
    }
  )

  const reloadPaymentMethods = async () => {
    const response = await getRequest(
      `/advertiser/contracts/${contractUuid}/form`
    )
    setPaymentMethods(response?.data?.paymentMethods)
  }

  const onSubmit = async (values) => {
    const result = await putRequest(`/advertiser/contracts/${contract.uuid}`, {
      contract: values,
    })

    if (result?.data?.success) {
      navigate(
        paths.publisherContractShortlinksPath(publisher.uuid, contract.uuid)
      )
    } else {
      console.error(result)
      showMessage(result?.message)
    }
  }

  const loading =
    contract === null || publisher === null || paymentMethods === null
  if (loading) {
    return <PageLoading />
  }

  return (
    <Container>
      <PageTitle
        title={contract.code}
        backPath={paths.publisherPath(publisher.uuid)}
        crumbs={{ 'Edit campaign': null }}
      />

      <PageContent>
        <PageList
          pages={['Campaign details', 'Billing', 'Smart links']}
          currentPage={currentPage - 1}
        />
        <Form
          {...{
            contract,
            isNewContract: false,
            isNewPublisher: false,
            paymentMethods,
            setPaymentMethods,
            showAddPaymentMethod,
            setShowAddPaymentMethod,
            currentPage,
            setCurrentPage,
            onSubmit,
          }}
        />
      </PageContent>

      <AddPaymentMethodDialog
        show={showAddPaymentMethod}
        toggle={setShowAddPaymentMethod}
        onDone={reloadPaymentMethods}
      />
    </Container>
  )
}
